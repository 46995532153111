import * as React from "react";
const EducationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M24.005 6.996 14.246.66a4.137 4.137 0 0 0-4.481 0L0 6.996l4.006 2.6v9.964c.094.094 2.499 2.436 8 2.436 5.501 0 7.906-2.342 8-2.436V9.592l3-1.948v11.351h1v-12l-.001.001Zm-4.999 12.13c-.548.438-2.667 1.87-7 1.87-4.301 0-6.447-1.437-7-1.872v-8.88l4.76 3.09a4.134 4.134 0 0 0 4.481-.001l4.759-3.09v8.883Zm-5.303-6.633a3.095 3.095 0 0 1-3.394 0L1.838 6.996 10.309 1.5a3.095 3.095 0 0 1 3.394 0l8.467 5.498-8.466 5.497h-.001Z"
    />
  </svg>
);
export default EducationIcon;
