import React from "react";
import LocationIconOutline from "../../../../assets/svg/LocationIconOutline";

const About = () => {
  return (
    <div className="flex flex-1 gap-10 flex-col  md:flex-row">
      <span className="font-montserrat text-primary font-normal text-xl flex md:w-3/12 mt-7">
        Providing high-caliber recruitment services Since 1989
      </span>
      <span className="font-montserrat text-primary font-medium text-xs flex md:w-7/12 text-justify leading-6 whitespace-pre-line flex-col">
        <div className="flex items-center gap-2 mb-3">
          <LocationIconOutline className={"w-4 h-4"} fill="#6f6f6f" />
          <span className="font-medium text-xs text-light-black">London</span>
        </div>
        Providing high-caliber recruitment services Since 1989 Lorem ipsum dolor
        sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        {"\n\n"}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </span>
    </div>
  );
};

export default About;
