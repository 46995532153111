import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCandidateDetails,
  getSkills,
  updateCandidate,
} from "../../../../endpoints/candidate.service";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../store/actions/auth.action";
import { candidateStatus } from "../../../../utils/constants";
import { useSnackbar } from "notistack";
import { getExperienceList } from "../../../../endpoints/experience.service";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../utils/theme";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { profileImage, uploadUrl } from "../../../../endpoints/images.service";

import ExperienceIcon from "../../../../assets/svg/ExperienceIcon";
import AboutIcon from "../../../../assets/svg/AboutIcon";
import SkillsIconn from "../../../../assets/svg/SkillsIconn";
import EducationIcon from "../../../../assets/svg/EducationIcon";
import ContactIcon from "../../../../assets/svg/ContactIcon";
import About from "../../../ProfilePage/Components/About";
import Experience from "../../../ProfilePage/Components/experience/experience";
import Skills from "../../../ProfilePage/Components/Skills";
import Education from "../../../ProfilePage/Components/Education";
import ContactDetails from "../../../ProfilePage/Components/ContactDetails";

const availabilityStatus = Object.entries(candidateStatus).map(
  ([key, value]) => ({
    title: value,
    id: key,
  })
);

const tabs = [
  {
    label: "About",
    icon: <AboutIcon />,
  },
  {
    label: "Experience",
    icon: <ExperienceIcon />,
  },
  {
    label: "Skills",
    icon: <SkillsIconn />,
  },
  {
    label: "Education & Certifications",
    icon: <EducationIcon />,
  },
  {
    label: "Contact Details",
    icon: <ContactIcon />,
  },
];

const RenderBody = forwardRef(({ activeTab, profileDetails }, ref) => {
  switch (activeTab) {
    case 0:
      return <About ref={ref} profileDetails={profileDetails} />;
    case 1:
      return (
        <Experience
          ref={ref}
          showProfile={true}
          candidateId={profileDetails?.candidateId}
        />
      );
    case 2:
      return <Skills candidateId={profileDetails?.candidateId} />;
    case 3:
      return <Education ref={ref} candidateId={profileDetails?.candidateId} />;
    case 4:
      return <ContactDetails ref={ref} profileDetails={profileDetails} />;
    default:
      return <div />;
  }
});

const ProfileTab = ({ profileDetails }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      jobTitle: user?.jobTitle,
      status: availabilityStatus?.find((x) => x.id === user?.status)?.id ?? "",
      location: user?.location,
      image: undefined,
      openProfileModel: false,
    },
  });

  const handleTabClick = (index) => {
    setIsEditing(false);
    setEditingTab(undefined);
    setActiveTab(index);
  };

  useEffect(() => {
    if (user) {
      setValue("jobTitle", user?.jobTitle);
      setValue("location", user?.location);
      setValue(
        "status",
        availabilityStatus?.find((x) => x.id === user?.status)?.id ?? ""
      );
    }
  }, [user]);

  const fetchExperienceList = useCallback(async () => {
    try {
      const { success, data } = await getExperienceList();
      if (success) {
        dispatch(setUserData({ experience: data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchUserSkills = useCallback(async () => {
    try {
      const { success, data } = await getSkills();
      if (success) {
        dispatch(setUserData({ skills: data }));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchExperienceList();
    fetchUserSkills();
  }, []);

  return (
    <div className="flex bg-white h-full overflow-hidden w-full shadow-[2px_2px_12px_0px_rgba(180,180,180,0.25)] rounded-lg px-20">
      <div className="flex flex-col flex-1">
        {/* Top container */}
        <div className="flex items-center bg-[#FAFAFA] py-2.5 px-2.5 border border-[#F7F7F7] mb-1 relative">
          {/* Image container */}
          <div className="absolute top-0 flex rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center max-md:h-36 max-md:w-36">
            {profileDetails?.candidateDetails?.profilePic ? (
              <img
                src={profileDetails?.candidateDetails?.profilePic}
                className="rounded-full h-40 w-40 object-fill z-10 max-md:h-36 max-md:w-36"
              />
            ) : (
              <span className="font-montserrat text-white font-semibold text-6xl uppercase">
                {profileDetails?.candidateDetails?.firstName?.charAt(0)}
                {profileDetails?.candidateDetails?.lastName?.charAt(0)}
              </span>
            )}
          </div>

          <div className="flex justify-center flex-1 gap-1 pl-20 items-center">
            <div />
            <div className="flex flex-col gap-2.5 my-2 items-center">
              <span className="font-montserrat text-primary text-2xl border-b border-primary w-max pb-1 px-4 max-md:text-base">
                {profileDetails?.candidateDetails?.firstName}{" "}
                {profileDetails?.candidateDetails?.lastName}
              </span>
              <span className="font-montserrat text-light-black font-medium text-xs max-[900px]:w-min text-center">
                {profileDetails?.candidateDetails?.jobTitle ??
                  "Senior Application Developer"}
              </span>
            </div>
          </div>
        </div>

        {/* Main Body Wrapper */}
        <div className="flex flex-col flex-1 overflow-hidden relative">
          <div className="flex items-center ml-28 pl-20 border-b gap-4 xl:pr-10 mt-[0.8rem] justify-between pr-4">
            {tabs?.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  style={{
                    borderBottom:
                      activeTab === index
                        ? "2px solid #EFBF04"
                        : "2px solid transparent",
                  }}
                  className="flex pb-1 h-full w-9 items-center justify-center lg:w-auto"
                >
                  <div className="lg:hidden">{item?.icon}</div>
                  <span className="font-montserrat text-primary font-medium text-xs hidden lg:block">
                    {item?.label}
                  </span>
                </button>
              );
            })}
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto no-scrollbar pl-24 mt-8">
            <RenderBody
              ref={formRef}
              activeTab={activeTab}
              profileDetails={profileDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
