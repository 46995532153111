export const JobTrack = [
  {
    id: "JO-123BNM",
    company: "Ashutec",
    title: "Offer Accepted",
    recruiterAgent: "Ankit",
    recruiterId: "ABCD-12",
    position: "System analyst",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-283BNM",
    key: "applied",
    company: "Suzuki",
    title: "Applied for position",
    mode: "via refferal",
    recruiterAgent: "John",
    recruiterId: "ABCD-13",
    position: "Systems Engineer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-123BNM",
    key: "call",
    company: "Stark industries",
    title: "Agent called",
    number: "+(44)12290 67823",
    recruiterAgent: "Tony",
    recruiterId: "ABCD-14",
    position: "Graphics Designer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-123BNM",
    company: "Oracal",
    title: "2nd stage interview",
    recruiterAgent: "Ankit",
    recruiterId: "ABCD-12",
    position: "System analyst",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-283BNM",
    key: "applied",
    company: "Tesla",
    title: "Applied for position",
    mode: "via refferal",
    recruiterAgent: "John",
    recruiterId: "ABCD-13",
    position: "Systems Engineer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-123BNM",
    key: "call",
    company: "Google",
    title: "Agent called",
    number: "+(44)12290 67823",
    recruiterAgent: "Tony",
    recruiterId: "ABCD-14",
    position: "Graphics Designer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-123BNM",
    company: "Ashutec",
    title: "Offer Accepted",
    recruiterAgent: "Ankit",
    recruiterId: "ABCD-12",
    position: "System analyst",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-283BNM",
    key: "applied",
    company: "Suzuki",
    title: "Applied for position",
    mode: "via refferal",
    recruiterAgent: "John",
    recruiterId: "ABCD-13",
    position: "Systems Engineer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
  {
    id: "JO-123BNM",
    key: "call",
    company: "Stark industries",
    title: "Agent called",
    number: "+(44)12290 67823",
    recruiterAgent: "Tony",
    recruiterId: "ABCD-14",
    position: "Graphics Designer",
    date: "16th November 2024",
    time: "12:00pm",
    progress: [
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer Accepted",
        key: "accept",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Offer received",
        key: "receive",
        accepted: "By Ashutec",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Interview scheduled - 20/3/25",
        key: "applied",
        accepted: "By you",
        date: "19th November 2024",
        time: "1:00pm",
      },
      {
        title: "Applied",
        key: "call",
        accepted: "By you",
        number: "+(44)12290 67823",
        date: "19th November 2024",
        time: "1:00pm",
      },
    ],
  },
];
