import { JobType } from "../../../utils/constants";
import { formatDate } from "../../../utils/helperFunction";

const JobCard = ({ data, isSelected }) => {
  const { title, industryType, location, updatedAt, jobType, salary, refNo } =
    data || {};

  const job = JobType.find((x) => x.value === jobType)?.label;

  return (
    <div
      className={`rounded-lg border border-[#ECECEC] p-4 hover:bg-light-white-700 w-full cursor-pointer relative
        ${isSelected ? "bg-light-white-700 " : ""}`}
    >
      {isSelected && (
        <div className="w-0 h-0 border-t-[6px] border-t-transparent border-l-[7.5px] border-l-[#ECECEC] border-b-[6px] border-b-transparent z-50 absolute -right-2"></div>
      )}
      <p className="font-semibold text-primary text-sm mb-6">{title}</p>

      <div className="flex flex-col gap-2 mb-6">
        <div className="flex gap-5">
          <span className="text-xs text-light-black w-28">Location:</span>
          <span className="font-medium text-primary text-xs flex-1">
            {location}
          </span>
        </div>
        <div className="flex gap-5">
          <span className="text-xs text-light-black w-28">Industry Type:</span>
          <span className="font-medium text-primary text-xs flex-1">
            {industryType}
          </span>
        </div>
        <div className="flex gap-5">
          <span className="text-xs text-light-black w-28">Job Type:</span>
          <span className="font-medium text-primary text-xs flex-1">{job}</span>
        </div>
        <div className="flex gap-5">
          <span className="text-xs text-light-black w-28">Salary Range:</span>
          <span className="font-medium text-primary text-xs flex-1">
            £{salary.min} - £{salary.max}
          </span>
        </div>
      </div>

      <div className="flex justify-between">
        <span className="font-medium text-[10px] text-light-black italic">
          Posted {formatDate(updatedAt)}
        </span>
        <span className="font-medium text-light-black text-[10px]">
          {refNo}
        </span>
      </div>
    </div>
  );
};

export default JobCard;
