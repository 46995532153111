import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { useState } from "react";
import ProfileTab from "./components/ProfileTab";
import SkillsMatchTab from "./components/SkillsMatchTab";
import TrackerTab from "./components/TrackerTab";
import ApplicationTab from "./components/ApplicationTab";
import MessagesTab from "./components/MessagesTab";
import { JobTrack } from "../../JobTrackPage/dummydata";

const tabs = [
  {
    title: "View Profile",
    value: 0,
  },
  {
    title: "View skills match",
    value: 1,
  },
  {
    title: "View Tracker",
    value: 2,
  },
  {
    title: "View Application",
    value: 3,
  },
  {
    title: "Messages",
    value: 4,
  },
];

const JobApplicantDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const [activeTab, setActiveTab] = useState(0);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <ProfileTab profileDetails={item} />;
      case 1:
        return <SkillsMatchTab skillsMatch={item?.skillsMatch} />;
      case 2:
        return (
          <TrackerTab
            progress={JobTrack[0]?.progress}
            applicationDetails={item}
          />
        );
      case 3:
        return <ApplicationTab applicationDetails={item} />;
      case 4:
        return <MessagesTab applicationDetails={item} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow px-6 py-2 overflow-hidden">
      <button
        onClick={() => navigate("/job")}
        className="flex items-center self-start my-3"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back
        </label>
      </button>

      {/* Tabs container */}
      <div className="flex items-center border-b border-gray-300 gap-16 mt-5 pb-2 pl-4">
        {tabs?.map((tab) => {
          const isActive = tab?.value === activeTab;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className={`flex items-center ${
                isActive ? "border-b border-primary" : ""
              }`}
            >
              <span
                className={`text-xs pb-0.5 ${
                  isActive ? "text-primary font-medium" : "text-gray-500"
                }`}
              >
                {tab?.title}
              </span>
            </button>
          );
        })}
      </div>

      <div className="flex items-center gap-20 bg-[#F1F5F9] my-3 rounded-md p-5">
        <span className={"font-semibold text-sm"}>
          {item?.jobDetails?.title ?? "-"}
        </span>
        <span className="bg-white text-primary font-medium text-xs py-1.5 px-8 rounded flex">
          {item?.jobDetails?.refNo ?? "-"}
        </span>
      </div>

      <div className="flex flex-col flex-1 w-full overflow-y-auto no-scrollbar">
        {renderContent()}
      </div>
    </div>
  );
};

export default JobApplicantDetail;
