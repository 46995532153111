import * as React from "react";
const ContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="m20.835 18.488-1.573 1.573c-1.576-.705-2.669-1.795-3.327-3.319l1.577-1.577-3.189-3.189-1.448 1.447a3.008 3.008 0 0 0-.874 2.145c0 3.704 4.729 8.432 8.433 8.432.82 0 1.582-.311 2.145-.875l1.447-1.447-3.189-3.19h-.002Zm1.035 3.931a2.01 2.01 0 0 1-1.438.581c-3.195 0-7.433-4.237-7.433-7.432 0-.553.206-1.063.581-1.438l.741-.74 1.775 1.775-1.334 1.333.116.303c.772 2.026 2.224 3.478 4.311 4.316l.307.123 1.338-1.338 1.775 1.776-.74.741h.001ZM2.5 23c-.827 0-1.5-.673-1.5-1.5S1.673 20 2.5 20h8.899a11.52 11.52 0 0 1-.536-1H5V1h13.5a.5.5 0 0 1 .5.5v12.325l1 1V1.5c0-.827-.673-1.5-1.5-1.5h-16A2.503 2.503 0 0 0 0 2.5v19C0 22.878 1.121 24 2.5 24h12.593c-.42-.306-.822-.644-1.211-1H2.5Zm0-22H4v18H2.5c-.565 0-1.081.195-1.5.513V2.5C1 1.673 1.673 1 2.5 1Z"
    />
  </svg>
);
export default ContactIcon;
