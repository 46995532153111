import CustomInput from "../../../components/CustomInput/CustomInput";
import { Dropdown } from "../../../components/Dropdown/Dropdown";

const Detail = ({ errors, control }) => {
  return (
    <div className="w-[80%] self-center">
      <div className="flex flex-col gap-7 w-1/2 justify-self-center">
        <CustomInput
          label="Job Title"
          placeholder="Jr. IT Analyst"
          name="jobTitle"
          rules={{ required: "This field is required." }}
          control={control}
          errors={errors}
        />
        <CustomInput
          label="Job Location"
          placeholder="Enter Job Location"
          name="jobLocation"
          rules={{ required: "This field is required." }}
          control={control}
          errors={errors}
        />
        <CustomInput
          label="Industry"
          placeholder="Enter Industry Type"
          name="industryType"
          rules={{ required: "This field is required." }}
          control={control}
          errors={errors}
        />

        <Dropdown
          label="Work place option"
          name="workPlaceOption"
          control={control}
          rules={{ required: "This field is required." }}
          options={["On-site", "Hybrid", "Remote"]}
          errors={errors}
        />

        <Dropdown
          label="Job Type"
          name="jobType"
          control={control}
          rules={{ required: "This field is required." }}
          options={["Full time", "Contract", "Part time"]}
          placeholder="Select the job-type"
          errors={errors}
        />

        <div>
          <span className="text-primary text-xs font-bold">Salary Range</span>
          <div className="flex gap-5 mt-1">
            <CustomInput
              label="From"
              placeholder="Enter minimum salary"
              name="salaryRangeFrom"
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label="To"
              placeholder="Enter maximum salary"
              name="salaryRangeTo"
              rules={{ required: "This field is required." }}
              control={control}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
