import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ChevronDownFilled from "../../assets/svg/ChevronDownFilled";
import BellIcon from "../../assets/svg/BellIcon";
import ChatIcon from "../../assets/svg/ChatIcon";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../store/actions/auth.action";
import { userTypes } from "../../utils/constants";
import { resetSearchData } from "../../store/actions/search.action";

const Navbar = () => {
  const dispatch = useDispatch();
  const { logged_in, user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showMenuOption, setShowMenuOption] = useState(false);
  const isReferrealAmbassador =
    user?.userType === userTypes.REFERRAL_AMBASSADOR;
  const isRecruiter = user?.userType === userTypes.RECRUITER;
  // Todo
  const isLoggedIn = (logged_in && user && user._id) || isReferrealAmbassador;
  const handleLogout = () => {
    setShowMenuOption(false);
    dispatch(removeToken());
    navigate("/");
    localStorage.removeItem("searchData");
    dispatch(resetSearchData());
  };

  return (
    <div className="sticky top-0 z-50">
      <div
        className={`w-screen ${
          pathname === "/" ? "" : "shadow-custom bg-white"
        }`}
      >
        <div
          className={`relative flex items-center justify-between mx-auto max-w-[1728px] py-3 ${
            pathname === "/" && "py-10"
          } ${
            pathname === "/" || !isLoggedIn
              ? "py-10 px-6 sm:px-24"
              : "pl-7 sm:pl-7 lg:pr-10 xl:pr-44 bg-white"
          }`}
        >
          {/* Logo */}
          <NavLink to="/" className="flex items-center hover:no-underline">
            <span
              className={`font-montserrat font-medium text-4xl text-secondary`}
            >
              C
            </span>
            <span
              className={`font-montserrat mt-1 font-normal tracking-[0.2em] pl-px text-lg`}
            >
              OMTRACKED
            </span>
          </NavLink>

          {/* Buttons */}
          {!isLoggedIn ? (
            <div className="flex gap-5">
              {(pathname === "/" || pathname.includes("/register")) && (
                <div className="flex gap-3">
                  {pathname.includes("/register") &&
                    pathname !== "/register-ambassador" && (
                      <button
                        type="button"
                        onClick={() => navigate("/register-ambassador")}
                        className="bg-white flex items-center justify-center rounded-full px-4 py-1.5 border border-primary"
                      >
                        <span className="text-primary">
                          REFERRAL AMBASSADOR REGISTER
                        </span>
                      </button>
                    )}
                  {pathname === "/register-ambassador" && (
                    <button
                      type="button"
                      onClick={() => navigate("/login-ambassador")}
                      className="bg-white flex items-center justify-center rounded-full px-4 py-1.5 border border-primary"
                    >
                      <span className="text-primary">
                        REFERRAL AMBASSADOR LOGIN
                      </span>
                    </button>
                  )}
                  <button
                    onClick={() => navigate("/login")}
                    className="rounded-full px-4 py-1.5 border hover:bg-[#EFEFEF]"
                  >
                    <span className="font-didact text-light-text text-base">
                      LOGIN
                    </span>
                  </button>
                </div>
              )}
              {(pathname === "/" ||
                pathname === "/login" ||
                pathname === "/login-ambassador") && (
                <div className="flex gap-3">
                  {pathname === "/login" && (
                    <button
                      type="button"
                      onClick={() => navigate("/login-ambassador")}
                      className="bg-white flex items-center justify-center rounded-full px-4 py-1.5 border border-primary"
                    >
                      <span className="text-primary">
                        REFERRAL AMBASSADOR LOGIN
                      </span>
                    </button>
                  )}
                  {pathname === "/login-ambassador" && (
                    <button
                      type="button"
                      onClick={() => navigate("/register-ambassador")}
                      className="bg-white flex items-center justify-center rounded-full px-4 py-1.5 border border-primary"
                    >
                      <span className="text-primary">
                        REFERRAL AMBASSADOR REGISTER
                      </span>
                    </button>
                  )}
                  <button
                    onClick={() => navigate("/register")}
                    className="rounded-full px-4 py-1.5 bg-secondary hover:bg-black"
                  >
                    <span className="font-didact text-base text-white">
                      REGISTER
                    </span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex gap-5">
              <button className="flex rounded-full border hover:bg-gray-100 h-9 w-9 p-1.5 relative">
                <ChatIcon className={"w-full h-full"} fill={"black"} />
              </button>

              <button className="flex rounded-full border hover:bg-gray-100 h-9 w-9 p-1.5 relative">
                <div className="w-3 h-3 p-1 rounded-full bg-[#C50000] flex items-center justify-center absolute top-1 right-1">
                  <span className="font-montserrat text-[9px] font-semibold text-white">
                    5
                  </span>
                </div>
                <BellIcon className={"w-full h-full"} fill={"black"} />
              </button>

              <div
                className="w-max relative"
                onMouseEnter={() => setShowMenuOption(true)}
                onMouseLeave={() => setShowMenuOption(false)}
              >
                <div className="flex items-center gap-1.5">
                  <span className="font-montserrat font-semibold text-sm text-light-black">
                    {isReferrealAmbassador && "(Referral Ambassador) "}
                    {user?.firstName} {user?.lastName}
                  </span>
                  <div
                    className={`transition-all duration-500 ${
                      showMenuOption ? "rotate-0" : "-rotate-90"
                    }`}
                  >
                    <ChevronDownFilled fill="black" />
                  </div>
                  <div className="h-10 w-10 rounded-full justify-center items-center flex bg-[#E1E0E0] bg-nwhited shadow-xl">
                    {user?.profilePic ? (
                      <img
                        src={user?.profilePic}
                        className="h-full w-full object-cover rounded-full"
                        alt="profileImg"
                      />
                    ) : (
                      <span className="font-montserrat text-white font-semibold text-xl">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </span>
                    )}
                  </div>
                </div>
                {/* MENU OPTION CONTAINER */}
                {showMenuOption ? (
                  <div
                    onMouseEnter={() => setShowMenuOption(true)}
                    onMouseLeave={() => setShowMenuOption(false)}
                    className="flex flex-col rounded-xl absolute bg-white right-0 shadow-xl border overflow-hidden"
                  >
                    <span
                      onClick={() =>
                        navigate(`${isRecruiter ? "/profile" : "my-profile"}`)
                      }
                      className="cursor-pointer hover:bg-gray-100 rounded-lg px-3 py-2 font-montserrat text-xs font-medium text-light-text"
                    >
                      View Profile
                    </span>
                    <span
                      onClick={() => navigate("/")}
                      className="cursor-pointer hover:bg-gray-100 rounded-lg px-3 py-2 font-montserrat text-xs font-medium text-light-text"
                    >
                      My Account
                    </span>
                    <span
                      onClick={() => navigate("/change-password")}
                      className="cursor-pointer hover:bg-gray-100 rounded-lg px-3 py-2 font-montserrat text-xs font-medium text-light-text"
                    >
                      Change Password
                    </span>
                    <span
                      onClick={handleLogout}
                      className="cursor-pointer hover:bg-gray-100 rounded-lg px-3 py-2 font-montserrat text-xs font-medium text-light-text"
                    >
                      Logout
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
