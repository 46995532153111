import * as React from "react";
const SkillsIconn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M4 5c1.378 0 2.5-1.121 2.5-2.5S5.378 0 4 0a2.503 2.503 0 0 0-2.5 2.5C1.5 3.879 2.622 5 4 5Zm0-4c.827 0 1.5.673 1.5 1.5S4.827 4 4 4s-1.5-.673-1.5-1.5S3.173 1 4 1Zm1.5 5h-3A2.503 2.503 0 0 0 0 8.5V17h2v7h1v-7h2v7h1v-7h2V8.5C8 7.121 6.878 6 5.5 6ZM7 16H1V8.5C1 7.673 1.673 7 2.5 7h3C6.327 7 7 7.673 7 8.5V16Zm7-13.5h1v1h-1c-.551 0-1 .448-1 1v7h2v1h-2v7c0 .552.449 1 1 1h1v1h-1c-1.103 0-2-.897-2-2v-7h-2v-1h2v-7c0-1.103.897-2 2-2Zm7.039.894 2.177-2.178.707.707-2.177 2.178a1.359 1.359 0 0 1-.965.398c-.35 0-.698-.133-.964-.397l-1.17-1.171.707-.707 1.17 1.17a.368.368 0 0 0 .516 0h-.001ZM24 4.675V5.5c0 .827-.673 1.5-1.5 1.5h-4c-.827 0-1.5-.673-1.5-1.5v-4c0-.827.673-1.5 1.5-1.5h3.104l-1 1H18.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4c.18 0 .332-.101.42-.245L24 4.674v.001Zm-2.961 7.219 2.177-2.178.707.707-2.177 2.178a1.359 1.359 0 0 1-.965.398c-.35 0-.698-.133-.964-.397l-1.17-1.171.707-.707 1.17 1.17a.368.368 0 0 0 .516 0h-.001Zm1.881 2.362L24 13.175V14c0 .827-.673 1.5-1.5 1.5h-4c-.827 0-1.5-.673-1.5-1.5v-4c0-.827.673-1.5 1.5-1.5h3.104l-1 1H18.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4c.18 0 .332-.1.42-.244Zm-1.881 6.138 2.177-2.178.707.707-2.177 2.178a1.359 1.359 0 0 1-.965.398c-.35 0-.698-.133-.964-.397l-1.17-1.171.707-.707 1.17 1.17a.368.368 0 0 0 .516 0h-.001Zm1.881 2.362L24 21.675v.825c0 .827-.673 1.5-1.5 1.5h-4c-.827 0-1.5-.673-1.5-1.5v-4c0-.827.673-1.5 1.5-1.5h3.104l-1 1H18.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4c.18 0 .332-.101.42-.245v.001Z"
    />
  </svg>
);
export default SkillsIconn;
