import * as React from "react";
const SubmitIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#FF9800"
      d="M19.156 4.462c.162-.16.08-.416-.132-.332L4.551 9.886a.713.713 0 0 0-.005 1.322l5.037 2.052a.713.713 0 0 0 .769-.152l8.804-8.646ZM10.917 13.552a.715.715 0 0 0-.16.783c.717 1.728 1.409 3.467 2.114 5.2a.713.713 0 0 0 1.323-.006l5.755-14.473c.067-.169-.132-.245-.261-.118l-8.771 8.614Z"
    />
  </svg>
);
export default SubmitIcon;
