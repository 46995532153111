import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getJobById } from "../../endpoints/job.service";
import { formatDate } from "../../utils/helperFunction";
import { useSelector } from "react-redux";
import { JobType, skillsUsed } from "../../utils/constants";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const JobDetailPage = ({ jobId }) => {
  const navigate = useNavigate();
  const { logged_in } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [activeTab, setActiveTab] = useState("description");

  const job = JobType.find((x) => x.value === jobDetails?.jobType)?.label;

  useEffect(() => {
    if (jobId && logged_in) {
      setLoading(true);
      getJobById(jobId)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setJobDetails(data);
          }
        })
        .finally(() => setLoading(false));
    } else if (jobId) {
      navigate(`/job-detail/${jobId}`);
    }
  }, [jobId]);

  if (loading) {
    return (
      <div className="flex justify-center mt-64">
        <LoadingAnimation color={"black"} />
      </div>
    );
  }

  return (
    <div className="flex rounded-lg w-full p-4">
      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar gap-3.5 w-full">
        {/* Left section */}
        <div className="flex flex-col flex-1">
          <div className="flex justify-between">
            <p className="font-semibold text-primary text-sm mb-6">
              {jobDetails?.title}
            </p>
            <span className="font-medium text-light-black text-[10px]">
              {jobDetails?.refNo}
            </span>
          </div>

          <div className="flex flex-col gap-2 mb-6">
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Location:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {jobDetails?.location}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Industry Type:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                {jobDetails?.industryType}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">Job Type:</span>
              <span className="font-medium text-primary text-xs flex-1">
                {job}
              </span>
            </div>
            <div className="flex gap-5">
              <span className="text-xs text-light-black w-28">
                Salary Range:
              </span>
              <span className="font-medium text-primary text-xs flex-1">
                £{jobDetails?.salary.min} - £{jobDetails?.salary.max}
              </span>
            </div>
          </div>

          <p className="font-medium text-xs text-light-black italic text-center">
            Posted {formatDate(jobDetails?.updatedAt)}
          </p>
        </div>

        {/* Action section */}
        <div className="flex gap-2">
          <button
            className="flex justify-center font-medium text-xs text-white rounded bg-[#FF9800] px-8 py-2 items-center gap-2"
            onClick={() => navigate(`${jobId}`)}
          >
            Apply
          </button>

          <button className="flex justify-center font-medium bg-[#F1F5F9] text-xs text-primary rounded border border-[#DDDDDD] px-8 py-2 items-center gap-2">
            Refer
          </button>

          <button className="flex justify-center font-medium bg-[#F1F5F9] text-xs text-primary rounded border border-[#DDDDDD] px-8 py-2 items-center gap-2">
            Track
          </button>
        </div>

        <div>
          {/* Tab buttons */}
          <div className="flex space-x-12 mb-6 mt-3">
            <button
              onClick={() => setActiveTab("description")}
              className={`py-1 px-4 text-xs font-semibold ${
                activeTab === "description"
                  ? "border-b-2 border-[#FF9800] text-primary"
                  : "text-light-grey border-b-2 border-transparent"
              }`}
            >
              Job Description
            </button>
            <button
              onClick={() => setActiveTab("skills")}
              className={`py-1 px-4 text-xs font-semibold ${
                activeTab === "skills"
                  ? "border-b-2 border-[#FF9800] text-primary"
                  : "text-light-grey border-b-2 border-transparent"
              }`}
            >
              Skills Required
            </button>
          </div>

          {/* Tab Content */}
          {activeTab === "description" && (
            <div className="my-3">
              <span className="font-semibold text-primary text-sm">
                Job description
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: jobDetails?.description,
                }}
                className="text-light-black text-xs mt-2"
              />
            </div>
          )}

          {activeTab === "skills" && jobDetails?.skills?.length > 0 && (
            <div className="w-3/4">
              <div className="grid flex-1 mt-7 mb-2 justify-center grid-cols-[1.9fr_0.75fr_0.6fr_1fr]">
                {["Required skill", "Rating", "Years", "When"].map((label) => (
                  <span
                    key={label}
                    className="text-xs font-semibold text-gray-700 flex-1"
                  >
                    {label}
                  </span>
                ))}
              </div>
              <div className="overflow-auto h-full">
                {jobDetails?.skills?.map((skill) => {
                  const lastUsed = Object.entries(skillsUsed).find(
                    ([key, value]) => value === skill.lastUsed
                  );
                  return (
                    <div
                      key={skill?._id}
                      className="grid space-x-3 py-2.5 items-center w-full justify-center relative grid-cols-[1.7fr_0.68fr_0.58fr_1fr]"
                    >
                      {[
                        skill.title,
                        `${skill.rating}/10`,
                        skill.years,
                        lastUsed ? lastUsed[0] : "N/A",
                      ].map((value, index) => (
                        <div
                          key={index}
                          className="border border-[#FAFAFA] bg-white px-4 py-2.5 text-xs font-medium text-primary rounded flex flex-1"
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetailPage;
