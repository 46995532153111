import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import regexList from "../../utils/regexList";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { signup } from "../../endpoints/auth.service";
import { useSnackbar } from "notistack";
import TickOutline from "../../assets/svg/TickOutline";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";

const RegisterPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      isLoading: false,
    },
  });

  const states = watch();

  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [selectedRole, setSelectedRole] = useState(params?.path || "employer");

  const handleRegister = async (fields) => {
    try {
      setValue("isLoading", true);
      const { data, success } = await signup({
        ...fields,
        userType: selectedRole === "employer" ? 2 : 3,
      });
      if (success) {
        if (!data?.verifiedEmail) {
          navigate("/generate-user-name", {
            state: {
              email: fields?.email,
              isSignup: true,
            },
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setValue("isLoading", false);
    }
  };

  const handleRoleChange = () => {
    setSelectedRole((prev) => {
      if (prev === "employer") {
        return "candidate";
      } else {
        return "employer";
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 relative bg-white w-max self-center justify-self-center overflow-y-scroll no-scrollbar">
        <button
          onClick={handleRoleChange}
          className="font-medium underline text-secondary mb-2 ml-10 absolute top-2 right-3"
        >
          {selectedRole === "employer"
            ? "Are you looking for job?"
            : "Are you an employer?"}
        </button>
        <label className="font-semibold text-3xl mb-2">
          Register {selectedRole === "employer" ? "as an employer" : ""}
        </label>
        <label className="text-light-black font-medium px-10">
          Create your account today and get started for free!
        </label>
        <form
          className="flex flex-col w-full pt-10 overflow-y-auto no-scrollbar h-max gap-1"
          onSubmit={handleSubmit(handleRegister)}
        >
          <div className="flex items-center justify-center gap-3">
            <CustomInput
              label={"First Name"}
              placeholder={"Enter your First Name"}
              name="firstName"
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label={"Last Name"}
              placeholder={"Enter your Last Name"}
              name="lastName"
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />
          </div>
          <div className="h-3" />
          {selectedRole === "employer" ? (
            <>
              <CustomInput
                label={"Company Name"}
                placeholder={"Enter your Company Name"}
                name="companyName"
                rules={{
                  required: "This field is required.",
                }}
                control={control}
                errors={errors}
              />
              <div className="h-3" />
            </>
          ) : null}
          <CustomInput
            label={"Email"}
            placeholder={"Enter your Email"}
            name="email"
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
          <div className="h-3" />
          <CustomInput
            label={"Confirm Email"}
            placeholder={"Enter your Confirm Email"}
            name="confirmEmail"
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList.email,
                message: "Please enter a valid email.",
              },
            }}
            control={control}
            errors={errors}
          />
          <div className="h-3" />
          <div className="flex items-center justify-center gap-3">
            <CustomInput
              label={"Password"}
              placeholder={"Enter your Password"}
              name="password"
              rules={{
                required: "This field is required.",
                pattern: {
                  value: regexList.password,
                  message: "Please enter a valid password.",
                },
              }}
              control={control}
              errors={errors}
            />
            <CustomInput
              label={"Confirm Password"}
              placeholder={"Enter your Password"}
              name="confirmPassword"
              rules={{
                required: "This field is required.",
                pattern: {
                  value: regexList.password,
                  message: "Please enter a valid password.",
                },
              }}
              control={control}
              errors={errors}
            />
          </div>

          {/* Checkbox container  */}
          <div className="flex items-center gap-2 mt-3">
            <div
              onClick={() => setKeepMeSignedIn((prev) => !prev)}
              className="border-2 border-[#6F6F6F] h-4 w-4 flex items-center justify-center cursor-pointer"
            >
              {keepMeSignedIn ? (
                <TickOutline className="w-1.5 h-1.5" fill="#6F6F6F" />
              ) : null}
            </div>
            <span className="font-montserrat font-medium text-primary text-sm">
              I agree with <span className="underline">Terms of Use</span> and{" "}
              <span className="underline">Privacy Policy</span>
            </span>
          </div>

          <button
            type="submit"
            disabled={states?.isLoading}
            className="bg-secondary flex items-center justify-center rounded-full mt-10 py-3"
          >
            {states?.isLoading && <LoadingAnimation />}
            <span className="text-white font-medium">Register</span>
          </button>
          <span className="mx-auto text-sm mt-1 font-medium text-light-black">
            Already have an account?{" "}
            <span
              onClick={() => navigate("/login")}
              className="text-secondary cursor-pointer"
            >
              Login
            </span>
          </span>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
