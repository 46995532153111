import { useCallback, useEffect, useState } from "react";
import { JobTrack } from "./dummydata";
import { useNavigate } from "react-router-dom";
import { candidateAppliedJobs } from "../../endpoints/job.service";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { colors } from "../../utils/theme";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import moment from "moment";

const JobTrackPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState();

  useEffect(() => {
    fetchAppliedJobs();
  }, [user]);

  const fetchAppliedJobs = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data, success } = await candidateAppliedJobs();
      if (success) {
        setJobList(data?.jobApplications);
      }
    } catch (error) {
      enqueueSnackbar(error + "Error fetching messages", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleItemClick = (item) => {
    navigate("/job-track-detail", { state: { item } });
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-6">
      <div className="flex flex-col gap-4 justify-around rounded-lg bg-[#F1F5F9] py-4 px-6 mb-5">
        <div className="flex items-center w-full gap-6">
          <div className="bg-white w-2/5 rounded text-primary">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full text-primary placeholder-light-grey rounded"
              placeholder="Search by job title"
            />
          </div>
          <div className="bg-white w-1/5 rounded">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full text-primary placeholder-light-grey rounded"
              placeholder="Sort by"
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <LoadingAnimation color={colors.PrimaryBlack} className={"w-4 h-4"} />
        </div>
      ) : (
        <div className="overflow-y-scroll no-scrollbar">
          {jobList?.map((data, index) => (
            <div
              className={`flex items-center gap-7 py-3.5 px-6 cursor-pointer ${
                data.title === "Offer Accepted" && "border"
              } border-[#DDDDDD] bg-[#FAFAFA] rounded-lg mb-3 hover:bg-gray-100`}
              onClick={() => handleItemClick(data)}
            >
              <div className="w-8 h-8 rounded-full border border-light-black flex items-center justify-center">
                <span className="text-primary text-xs">{index + 1}</span>
              </div>

              <div className="flex flex-col gap-3.5 flex-1">
                <div className="flex flex-shrink-0 font-medium text-xs text-light-black gap-4">
                  {moment(data?.job?.createdAt).format("h:mm a DD MMMM YYYY")}
                </div>

                <div
                  className={`py-1.5 rounded text-center text-xs font-medium w-3/5 ${
                    data?.title === "Offer Accepted"
                      ? "bg-light-black text-white"
                      : "bg-[#F1F5F9] border border-[#DDDDDD] text-primary"
                  }`}
                >
                  Applied
                </div>
              </div>

              <div className="flex flex-col gap-3.5 flex-1">
                <div className="text-xs font-medium text-light-black px-5">
                  Agency : {data?.job?.companyDetails?.name}
                </div>
                <div className="bg-white text-primary font-medium text-xs py-1.5 px-5 rounded w-4/5">
                  {data?.job?.title}
                </div>
              </div>

              <p className="bg-white text-primary font-medium text-xs py-1.5 px-8 flex self-end rounded text-center">
                {data?.job?.refNo}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobTrackPage;
