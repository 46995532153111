import { forwardRef, useEffect, useImperativeHandle } from "react";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LocationIconOutline from "../../../assets/svg/LocationIconOutline";

const About = forwardRef(({ isEditing, handleSave, profileDetails }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      about: user?.about,
    },
  });

  useImperativeHandle(ref, () => handleSubmit(handleSave));

  useEffect(() => {
    if (isEditing) {
      setValue("about", user?.about ?? "");
    }
  }, [user?.about, isEditing]);

  return (
    <div>
      {!isEditing ? (
        <div className="h-full flex flex-1 flex-col overflow-y-scroll no-scrollbar w-full pr-20 pt-5">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <LocationIconOutline className={"w-4 h-4"} fill="#6f6f6f" />
              <span className="font-medium text-xs text-light-black">
                {profileDetails?.candidateDetails?.location ?? user?.location}
              </span>
            </div>
            <div
              className="font-montserrat text-primary font-medium text-xs leading-5 mb-3"
              dangerouslySetInnerHTML={{
                __html: profileDetails?.candidateDetails?.about ?? user?.about,
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
            About
          </span>
          <div className="mr-4">
            <Controller
              name={"about"}
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => {
                return (
                  <div className="flex flex-col gap-1">
                    <QuillEditor {...field} />
                    {errors["about"]?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors["about"]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default About;
