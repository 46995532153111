import * as React from "react";
const LocationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g fill="#6F6F6F" clipPath="url(#a)">
      <path d="m6.975 14.005-.406-.349c-.561-.47-5.456-4.697-5.456-7.789a5.862 5.862 0 0 1 11.724 0c0 3.092-4.895 7.32-5.453 7.791l-.409.347Zm0-12.732a4.6 4.6 0 0 0-4.594 4.594c0 1.943 3.011 5.046 4.594 6.466 1.583-1.42 4.595-4.526 4.595-6.466a4.6 4.6 0 0 0-4.595-4.594Z" />
      <path d="M6.976 8.19a2.324 2.324 0 1 1 0-4.646 2.324 2.324 0 0 1 0 4.647Zm0-3.485a1.162 1.162 0 1 0 0 2.324 1.162 1.162 0 0 0 0-2.324Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default LocationIcon;
