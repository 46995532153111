import ChevronDown from "../../assets/svg/ChevronDown";
import JobCard from "./components/JobCard";
import { useEffect } from "react";
import { getJobs } from "../../endpoints/job.service";
import { useState } from "react";
import FileIcon from "../../assets/images/file.png";
import JobDetailPage from "../JobDetailPage/JobDetailPage";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import { isNullOrEmpty } from "../../utils/helperFunction";

const JobsListPage = () => {
  const [jobList, setJobList] = useState([]);
  const [isJobLoading, setIsJobLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    setIsJobLoading(true);
    getJobs()
      .then((response) => {
        const { success, data } = response;
        if (success) {
          setJobList(data);
        }
      })
      .finally(() => setIsJobLoading(false));
  }, []);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full h-full p-6">
      {/* Heading container */}
      <div className="flex flex-col gap-4 justify-around rounded overflow-hidden bg-[#F1F5F9] py-3 px-4">
        <div className="flex items-center w-full gap-3">
          <div className="bg-white flex-1 text-primary">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full text-primary placeholder-light-black"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <div className="bg-white flex-1 flex px-3 h-8 items-center cursor-pointer">
            <span className="text-xs font-medium w-full text-light-black">
              Select industry type
            </span>
            <ChevronDown className="w-5 h-5" fill="black" />
          </div>
          <div className="bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full text-primary placeholder-light-black"
              placeholder="Search by suburb, city or region..."
            />
          </div>
          <button className="font-medium text-sm h-8 bg-white px-5 text-[#4d4d4d]">
            Search
          </button>
        </div>
      </div>

      <div className="flex items-center gap-3 mt-2">
        <div className="flex items-center border border-[#F6F6F6] text-light-grey rounded text-xs py-1 px-2 gap-20 bg-[#FAFAFA]">
          <span>All work types</span>
          <ChevronDown className="w-5 h-5" fill="#BABABA" />
        </div>
        <div className="flex items-center border border-[#F6F6F6] text-light-grey rounded text-xs py-1 px-2 gap-16 bg-[#FAFAFA]">
          <span>Paying $0</span>
          <ChevronDown className="w-5 h-5" fill="#BABABA" />
        </div>
        <div className="flex items-center border border-[#F6F6F6] text-light-grey rounded text-xs py-1 px-2 gap-10 bg-[#FAFAFA]">
          <span>to $350</span>
          <ChevronDown className="w-5 h-5" fill="#BABABA" />
        </div>
        <div className="flex items-center border border-[#F6F6F6] text-light-grey rounded text-xs py-1 px-2 gap-20 bg-[#FAFAFA]">
          <span>Listed Anytime</span>
          <ChevronDown className="w-5 h-5" fill="#BABABA" />
        </div>
      </div>

      {isJobLoading ? (
        <div className="flex justify-center mt-64">
          <LoadingAnimation color={"black"} />
        </div>
      ) : isNullOrEmpty(jobList) ? (
        <div className="flex flex-col items-center justify-center h-full rounded-lg border border-[#ECECEC]">
          <img src={FileIcon} alt="File Icon" />
          <p className="text-sm text-primary font-semibold">No job listed</p>
          <p className="text-xs text-light-black font-medium">
            Search jobs to display details
          </p>
        </div>
      ) : (
        <>
          <p className="text-[#4D4D4D] text-xs mt-5 mb-6">
            Showing {jobList.length} results
          </p>

          <div className="flex flex-1 min-h-0">
            <div className="overflow-y-auto no-scrollbar">
              <div className="flex flex-col gap-3.5 pr-5">
                {jobList?.map((item) => (
                  <div key={item?._id} onClick={() => handleJobSelect(item)}>
                    <JobCard
                      data={item}
                      isSelected={selectedJob?._id === item._id}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div
              className={`flex-1 rounded-lg border border-[#ECECEC] ${
                selectedJob ? "bg-[#FAFAFA]" : "bg-white"
              } flex flex-col overflow-y-auto no-scrollbar`}
            >
              {selectedJob ? (
                <JobDetailPage jobId={selectedJob?._id} />
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <img src={FileIcon} alt="File Icon" />
                  <p className="text-sm text-primary font-semibold">
                    No job selected
                  </p>
                  <p className="text-xs text-light-black font-medium">
                    Choose a job to display details
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JobsListPage;
