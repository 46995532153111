import { Link, useNavigate } from "react-router-dom";
import ChevronDown from "../../assets/svg/ChevronDown";
import PlusIcon from "../../assets/svg/PlusIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJobDetail, setJobs } from "../../store/actions/job.action";
import { getJobs } from "../../endpoints/job.service";
import dayjs from "dayjs";
import { datePostedType, JobStatus, JobType } from "../../utils/constants";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import { isNullOrEmpty } from "../../utils/helperFunction";
import moment from "moment";

const tabs = [
  {
    title: "Publish",
    value: 0,
  },
  {
    title: "Expired",
    value: 1,
  },
  {
    title: "Draft",
    value: 2,
  },
];

const JobCard = ({ data, activeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        dispatch(setJobDetail(data));
        navigate(`/job/${data?._id}/job-post`);
      }}
      className="flex items-center justify-between bg-[#fafafa] rounded-md w-ful px-6 py-4 hover:bg-gray-100 cursor-pointer"
    >
      <div className="flex items-center gap-10">
        <span className="flex text-xs border border-black rounded-full h-8 w-8 justify-center items-center bg-white">
          5
        </span>
        <div className="flex flex-col gap-3">
          <span className="text-xs text-light-black font-medium">
            Published{" "}
            {moment(data?.publishedDate).format("h:mm a DD MMMM YYYY")}
          </span>
          <div className="flex items-center justify-center rounded-sm bg-[#F1F5F9] border py-1">
            <span className="text-xs font-medium text-primary">
              {tabs?.find((item) => item?.value === activeTab)?.title}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-xs text-light-black font-medium pl-4">
            Client : {data?.companyDetails?.name}
          </span>
          <div className="flex items-center rounded-sm bg-white py-1 px-4 w-64">
            <span className="text-xs font-medium text-primary">
              {data?.title}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-md text-xs font-medium py-1 px-8 mr-5 self-end">
        {data?.refNo}
      </div>
    </div>
  );
};

const JobPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state?.job);
  const [loading, setLoading] = useState(jobs?.length === 0);
  const [activeTab, setActiveTab] = useState(0);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      search: undefined,
      searchBy: "Search by",
    },
  });
  const fields = watch();

  const jobList = jobs?.filter((item) => {
    if (activeTab === 0) {
      return item?.status === JobStatus.LIVE;
    } else if (activeTab === 1) {
      return item?.status === JobStatus.EXPIRED;
    } else if (activeTab === 2) {
      return item?.status === JobStatus.DRAFT;
    }
  });

  useEffect(() => {
    fetchJobs();
  }, [fields?.search]);

  const fetchJobs = () => {
    getJobs(
      !isNullOrEmpty(fields?.search) && {
        filter: {
          ...(fields?.searchBy === "Search by" && {
            searchText: fields.search,
          }),
          ...(fields?.searchBy === "Industry Type" && {
            industryType: fields.search,
          }),
          ...(fields?.searchBy === "Job Type" && {
            jobType: JobType.find((x) => x.label === fields.search)?.value,
          }),
          ...(fields?.searchBy === "Date Posted" && {
            datePosted: datePostedType.find((x) => x.label === fields.search)
              ?.value,
          }),
        },
      }
    )
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(setJobs(data));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <div className="flex  items-center justify-between w-full gap-8 bg-[#F1F5F9] rounded-md px-3 py-1">
        <div className="flex flex-1 w-full items-center gap-8">
          {/* Input container */}
          <div className="flex w-[50%] rounded-lg gap-3 items-center justify-between bg-light-white-500 py-1.5">
            {/* <div className="min-w-[28%]">
              <CustomInput
                name="searchBy"
                control={control}
                errors={errors}
                type="dropdown"
                dropdownList={[
                  "Search by",
                  "Industry Type",
                  "Job Type",
                  "Date Posted",
                ]}
              />
            </div> */}
            <CustomInput
              key={fields.searchBy}
              name="search"
              control={control}
              className="border-none"
              errors={errors}
              // placeholder={
              //   (fields.searchBy === "Search by" &&
              //     "Enter value for Search by") ||
              //   (fields.searchBy === "Industry Type" &&
              //     "Enter value for Industry Type")
              // }
              placeholder="Search"
              type={
                fields.searchBy === "Search by" ||
                fields.searchBy === "Industry Type"
                  ? "text"
                  : "dropdown"
              }
              dropdownList={
                fields.searchBy === "Job Type"
                  ? JobType.map((x) => x.label)
                  : datePostedType?.map((x) => x.label)
              }
            />
          </div>
        </div>

        <button
          onClick={() => navigate("create")}
          className="flex items-center justify-center border bg-white rounded-md px-4 py-2 gap-1 text-gray-500 font-medium text-xs"
        >
          Create a job ad
        </button>
      </div>

      <div className="flex items-center border-b border-[#DDDDDD] gap-16 mt-5 pb-2 pl-4">
        {tabs?.map((tab) => {
          const isActive = tab?.value === activeTab;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className={`flex items-center ${
                isActive ? "border-b border-primary" : ""
              }`}
            >
              <span
                className={`text-xs pb-0.5 ${
                  isActive ? "text-primary font-medium" : "text-gray-500"
                }`}
              >
                {tab?.title}
              </span>
            </button>
          );
        })}
      </div>

      {/* List container */}
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : jobs?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Job created</p>
          <p className="mt-2 mb-5">Create a Job now!</p>
          <button
            onClick={() => navigate("create")}
            className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
          >
            <PlusIcon className={"w-4 h-4"} fill={"white"} />
            Create Job Post
          </button>
        </div>
      ) : (
        <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
          {jobList?.length ? (
            jobList?.map((job) => {
              return (
                <JobCard key={job?._id} data={job} activeTab={activeTab} />
              );
            })
          ) : (
            <div className="flex-grow">
              <span className="m-auto font-medium text-xs">No jobs found.</span>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      {/* Search and filter section */}
      <div className="flex  items-center justify-between w-full gap-8">
        <div className="flex flex-1 w-full items-center gap-8">
          {/* Input container */}
          <div className="flex w-2/3 rounded-lg gap-3 items-center justify-between bg-light-white-500 px-3 py-1.5">
            <div className="min-w-[28%]">
              <CustomInput
                name="searchBy"
                control={control}
                errors={errors}
                type="dropdown"
                dropdownList={[
                  "Search by",
                  "Industry Type",
                  "Job Type",
                  "Date Posted",
                ]}
              />
            </div>
            <CustomInput
              key={fields.searchBy}
              name="search"
              control={control}
              className="border-none"
              errors={errors}
              placeholder={
                (fields.searchBy === "Search by" &&
                  "Enter value for Search by") ||
                (fields.searchBy === "Industry Type" &&
                  "Enter value for Industry Type")
              }
              type={
                fields.searchBy === "Search by" ||
                fields.searchBy === "Industry Type"
                  ? "text"
                  : "dropdown"
              }
              dropdownList={
                fields.searchBy === "Job Type"
                  ? JobType.map((x) => x.label)
                  : datePostedType?.map((x) => x.label)
              }
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
            <label className="text-primary text-sm font-medium">All Jobs</label>
            <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
          </button>
        </div>

        <button
          onClick={() => navigate("create")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Create Job Post
        </button>
      </div>

      {/* List container */}
      {loading ? (
        <div className="gap-2.5 flex mt-16 flex-col">
          {Array.from({ length: 10 }, (x, i) => i).map((key) => {
            return (
              <div className="flex justify-between">
                <div
                  style={{
                    borderWidth: 1,
                  }}
                  className="flex rounded-lg w-full pl-3 pr-8 py-4 gap-1.5"
                >
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                  <div className="h-3 rounded bg-gray-200 w-1/4"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : jobs?.length === 0 ? (
        <div className="flex flex-1 h-screen flex-col items-center justify-center">
          <p className="text-primary font-semibold">No Job created</p>
          <p className="mt-2 mb-5">Create a Job now!</p>
          <button
            onClick={() => navigate("create")}
            className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
          >
            <PlusIcon className={"w-4 h-4"} fill={"white"} />
            Create Job Post
          </button>
        </div>
      ) : (
        <div className="flex flex-col mt-4 max-h-fit overflow-y-scroll no-scrollbar gap-2">
          <table class="table-auto mt-3">
            <thead className="border-b">
              <tr>
                <th className="text-left font-semibold py-3 pl-6 text-sm text-primary">
                  Job Reference
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Job Title
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Company Name
                </th>
                <th className="text-left font-semibold py-3 text-sm text-primary">
                  Publish date
                </th>
                <th className="text-left font-semibold py-3 pr-6 text-sm text-primary">
                  Live
                </th>
              </tr>
            </thead>
            <tbody>
              {jobs?.map((job) => {
                return (
                  <tr
                    key={job?._id}
                    className="hover:bg-light-white-700 cursor-pointer"
                    onClick={() => {
                      dispatch(setJobDetail(job));
                      navigate(`/job/${job._id}/job-post`);
                    }}
                  >
                    <td className="font-medium py-3 pl-6 border-b text-xs text-light-black">
                      {job?.refNo ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {job?.title ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {job?.companyDetails?.name ?? "-"}
                    </td>
                    <td className="font-medium py-3 border-b text-xs text-light-black">
                      {dayjs(job?.createdAt).format("DD MMM YYYY") ?? "-"}
                    </td>
                    <td className="font-medium py-3 pr-6 border-b text-xs text-light-black capitalize">
                      {Object.entries(JobStatus)
                        .find(([key, value]) => value === job?.status)?.[0]
                        ?.toLocaleLowerCase()}
                      {/* {job?.status === JobStatus.LIVE
                        ? "Live"
                        : job?.status === JobStatus.CLOSED
                        ? "Closed"
                        : "Draft"} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default JobPage;
