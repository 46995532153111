import { forwardRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReferIcon from "../../../assets/svg/ReferIcon";
import TrackingIcon from "../../../assets/svg/TrackingIcon";
import LocationIconOutline from "../../../assets/svg/LocationIconOutline";
import ShareIconOutline from "../../../assets/svg/ShareIconOutline";
import LikeIconLeft from "../../../assets/svg/LikeIconLeft";
import { candidateStatus } from "../../../utils/constants";
import EditIcon from "../../../assets/svg/EditIcon";
import ChevronDownFilled from "../../../assets/svg/ChevronDownFilled";
import About from "./Components/About";
import Jobs from "./Components/Jobs";
import Staff from "./Components/Staff";
import Partner from "./Components/Partner";
import { profileImage, uploadUrl } from "../../../endpoints/images.service";
import { updateCandidate } from "../../../endpoints/candidate.service";
import { setUserData } from "../../../store/actions/auth.action";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { colors } from "../../../utils/theme";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useSnackbar } from "notistack";

const tabs = ["About", "Jobs", "Staff", "Partners", "Contact Details"];

const RenderBody = forwardRef(({ activeTab }, ref) => {
  switch (activeTab) {
    case 0:
      return <About />;
    case 1:
      return <Jobs />;
    case 2:
      return <Staff />;
    case 3:
      return <Partner />;
    default:
      return <div />;
  }
});

const AgentProfile = () => {
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const cropperRef = useRef(null);
  const filePickerRef = useRef();
  const { user, companyDetails } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle,
      location: user?.location,
      image: undefined,
      openProfileModel: false,
    },
  });
  const fields = watch();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSave = async (formData) => {
    console.log("formdata", formData);
  };

  const closeModal = () => {
    setValue("openProfileModel", false);
    setValue("image", undefined);
    setValue("file", undefined);
    setValue("profileImage", undefined);
  };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      setValue("file", file, { shouldDirty: true });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("image", reader.result, { shouldDirty: true });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error during file upload or API calls:", error);
    }
  };

  const getCroppedImage = (e) => {
    e.preventDefault();

    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      if (cropperInstance) {
        const croppedDataUrl = cropperInstance.getCroppedCanvas().toDataURL();
        setValue("profileImage", croppedDataUrl);
        setValue("image", undefined);
      } else {
        console.error("Cropper instance is not found");
      }
    } else {
      console.error("cropperRef.current is not initialized");
    }
  };

  const updateProfile = async (payload) => {
    try {
      setIsLoading(true);
      if (payload?.profileImage) {
        const { data } = await profileImage();
        if (!data?.data || !data?.data.uploadURL) {
          throw new Error("Failed to get upload URL");
        }
        const formData = new FormData();
        const base64Data = payload?.profileImage?.split(";base64,").pop();
        const byteArray = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: payload?.file?.type });
        formData.append("file", blob, payload?.file?.name);
        const fileResponse = await uploadUrl(data?.data.uploadURL, formData);
        delete payload["file"];
        delete payload["profileImage"];
        delete payload["image"];
        const picURl = fileResponse?.variants?.find(
          (x) => x.split("/").slice(-1)[0] === "public"
        );
        payload["profilePic"] = picURl;
      }
      const { success, data: userApiData } = await updateCandidate(payload);
      if (success) {
        dispatch(setUserData(userApiData));
        setValue("profileImage", undefined);
        setValue("image", undefined);
        setValue("openProfileModel", false);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex bg-white flex-col flex-1 h-full overflow-hidden w-full shadow-[2px_2px_12px_0px_rgba(180,180,180,0.25)] rounded-lg p-4">
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] mb-1 relative">
        {/* Image container */}
        <div className="absolute top-0 flex rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
          {user?.profilePic ? (
            <img
              src={user?.profilePic}
              className="rounded-full h-40 w-40 object-fill"
            />
          ) : (
            <span className="font-montserrat text-white font-semibold text-6xl uppercase">
              {companyDetails?.name?.charAt(0)}
            </span>
          )}
        </div>

        <div className="flex justify-between flex-1 gap-1 pl-96">
          <div className="flex flex-col gap-2 items-center">
            <span className="font-montserrat text-primary font-semibold text-2xl border-b border-primary w-max">
              {companyDetails?.name}
            </span>
            <span className="font-montserrat text-primary font-medium text-xs max-[900px]:w-min">
              {user?.jobTitle}
            </span>
          </div>
          <div className="flex flex-col justify-between items-end relative">
            <span className="font-montserrat text-light-black font-medium text-[11px] max-[900px]:w-min italic">
              {user?.refNo}
            </span>
          </div>
        </div>
      </div>
      {/* Main Body Wrapper */}
      <div className="flex flex-col mt-4 flex-1 overflow-hidden relative">
        <div className="pl-36">
          <div className="h-px w-[80%] bg-[#F5F5F5] absolute right-0 top-[3.2rem]"></div>
          {/* Tab section */}
          <div className="flex items-center justify-evenly mt-[0.8rem]">
            {tabs?.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleTabClick(index)}
                  style={{
                    borderBottom:
                      activeTab === index
                        ? "1px solid #2E2E2E"
                        : "2px solid transparent",
                  }}
                  className="px-2.5"
                >
                  <span className="font-montserrat text-primary font-medium text-xs">
                    {item}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <div className={`flex-1 flex overflow-y-auto mt-16 no-scrollbar`}>
          <div className="flex flex-col pr-20 pl-5 items-center gap-3">
            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <ShareIconOutline className={"w-6 h-6"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Share
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Track
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <ReferIcon className="w-6 h-6" />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Refer
              </span>
            </div>

            <div className="group flex flex-col justify-center w-14 h-14 border rounded-md items-center cursor-pointer gap-1 bg-gray-50">
              <div className="flex border border-primary h-[22px] w-[22px] rounded-full justify-center items-center">
                <LikeIconLeft className={"w-5 h-5 mt-0.5"} fill={"#2E2E2E"} />
              </div>
              <span className="font-medium uppercase text-[8px] text-light-black">
                Likes
              </span>
            </div>
          </div>
          <div className="overflow-y-auto no-scrollbar">
            <RenderBody ref={formRef} activeTab={activeTab} />
          </div>
        </div>
      </div>
      {fields?.openProfileModel && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={closeModal} // Close modal when clicking outside the image
        >
          <div
            className="flex overflow-hidden flex-col bg-white py-6 px-6 rounded-lg relative w-11/12 max-w-[50%]"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >
            <div className="flex flex-1 items-center justify-between pb-6">
              <span className="font-montserrat text-primary font-bold text-xl">
                Edit
              </span>
              <button
                onClick={closeModal}
                className="self-end text-black text-2xl font-semibold"
              >
                X
              </button>
            </div>
            {!fields?.image && (
              <div className="flex flex-1 px-8 gap-14">
                <div className="flex flex-col flex-1 max-w-[40%] gap-6">
                  <div className="flex self-center rounded-full h-40 w-40 bg-[#E1E0E0] items-center justify-center">
                    {fields?.profileImage || user?.profilePic ? (
                      <img
                        src={fields?.profileImage || user?.profilePic}
                        className="rounded-full h-40 w-40 object-fill"
                      />
                    ) : (
                      <span className="font-montserrat text-white font-semibold text-6xl">
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => filePickerRef?.current?.click()}
                    className="flex  items-center border rounded-lg overflow-hidden pr-2"
                  >
                    <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                      PNG/JPG
                    </span>
                    <span className="text-xs flex font-medium px-3 text-ngray flex-1 text-left whitespace-nowrap">
                      {fields?.file
                        ? fields?.file?.name
                        : user?.profilePic
                        ? user?.profilePic
                        : "Upload logo here"}
                    </span>
                  </button>
                  <input
                    ref={filePickerRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="flex flex-1 flex-col gap-5">
                  <CustomInput
                    label="First Name"
                    name="firstName"
                    placeholder={"Ex: Jonh"}
                    control={control}
                    errors={errors}
                  />
                  <CustomInput
                    label="Last Name"
                    name="lastName"
                    placeholder={"Ex: doe"}
                    control={control}
                    errors={errors}
                  />
                  <CustomInput
                    label="Job Title"
                    name="jobTitle"
                    placeholder={"Ex: Project Manager"}
                    control={control}
                    errors={errors}
                  />
                  <CustomInput
                    label={"Location"}
                    name="location"
                    placeholder={"Ex: United Kingdom"}
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-col mt-6">
              {fields?.image && (
                <Cropper
                  src={fields?.image}
                  ref={cropperRef}
                  style={{ height: 400, width: "100%" }}
                  aspectRatio={1}
                  viewMode={2}
                  guides={false}
                  minCropBoxWidth={50} // Minimum width for the crop area
                  minCropBoxHeight={50} // Minimum height for the crop area
                />
              )}
              <style>
                {`
                    /* Ensure the cropper's crop box is circular */
                    .cropper-face,
                    .cropper-view-box,
                    .cropper-crop-box {
                      border-radius: 50% !important; /* Make crop area circular */
                      border: 2px solid white !important; /* Optional: Set the color of the circular crop lines */
                    }

                    .cropper-face {
                      background-color: rgba(0, 0, 0, 0.3) !important; /* Optional: Set the background color of the circular crop area */
                    }

                    .cropper-view-box {
                      border-radius: 50% !important; /* Make sure the selection area is circular */
                    }

                    .cropper-crop-box {
                      border-radius: 50% !important; /* Ensure the cropping boundary is circular */
                    }
                  `}
              </style>
              <div className="mt-10 flex justify-end">
                <button
                  disabled={isLoading}
                  onClick={(e) => {
                    if (fields?.image) {
                      getCroppedImage(e);
                    } else {
                      updateProfile(fields);
                    }
                  }}
                  className="bg-primary items-center flex text-white px-7 py-2.5 text-xl rounded-md"
                >
                  {isLoading && <LoadingAnimation color={colors.White} />}
                  {fields?.image ? "Crop Image" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentProfile;
