import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import {
  addCertificateOrEducation,
  getCertificateOrEducation,
  updateCertificateOrEducation,
} from "../../../endpoints/education.service";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../store/actions/auth.action";
import LoadingAnimation from "../../../assets/svg/LoadingAnimation";
import { colors } from "../../../utils/theme";

const EducationInput = ({ education, customId, setEducations, isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      isLoading: false,
    },
  });
  const statas = watch();
  const [editable, setEditable] = useState(false);

  const handleSaveEducation = async (data) => {
    try {
      setValue("isLoading", true);

      const payload = { ...data, type: education?.type };
      let response;

      if (education?._id) {
        // Update existing education
        payload.id = education._id;
        response = await updateCertificateOrEducation(payload);
      } else {
        // Add new education
        delete payload._id;
        delete payload.id;
        response = await addCertificateOrEducation(payload);
      }

      const { data: responseData, success } = response;
      if (success) {
        education?._id
          ? setEducations((prev) => {
              return prev?.map((edu) => {
                if (edu?._id === education?._id) {
                  return { isEditing: false, ...responseData };
                } else {
                  return edu;
                }
              });
            })
          : setEducations((prev) => {
              return prev?.map((exp, index) => {
                if (index === customId) {
                  return { isEditing: false, ...responseData };
                } else {
                  return exp;
                }
              });
            });
        setEditable(false);
        const successMessage = education?._id
          ? `${
              education?.type == 1 ? "Education" : "Certificate"
            } updated successfully.`
          : `${
              education?.type == 1 ? "Education" : "Certificate"
            } added successfully.`;
        enqueueSnackbar(successMessage, { variant: "success" });
      }
    } catch (error) {
      console.error("Error saving education:", error);
      enqueueSnackbar(
        `An error occurred while saving ${
          education?.type == 1 ? "Education" : "Certificate"
        }.`,
        {
          variant: "error",
        }
      );
    } finally {
      setValue("isLoading", false);
    }
  };

  useEffect(() => {
    setEditable(
      education?.isEditing !== undefined ? education?.isEditing : false
    );
  }, [education?.isEditing]);

  useEffect(() => {
    Object.entries(education)?.forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [education]);

  const handleSaveAndUpdateButton = () => {
    if (editable && Object.keys(dirtyFields)?.length > 0) {
      handleSubmit(handleSaveEducation)();
      return;
    } else if (editable && Object.keys(dirtyFields)?.length === 0) {
      setEducations((prev) => {
        return prev?.map((edu) => {
          if (edu?._id === education?._id) {
            return { isEditing: false, ...edu };
          } else {
            return edu;
          }
        });
      });

      return;
    }
    setEditable(true);
  };

  return (
    <div
      className={`flex flex-col ${
        editable ? "border rounded-md px-8 py-3" : ""
      }`}
    >
      {isEditing && (
        <p
          className={`text-[#6f6f6f] items-center text-xs font-medium cursor-pointer flex justify-end ${
            !editable ? "mt-0" : ""
          }`}
          onClick={handleSaveAndUpdateButton} // Toggle editing state
        >
          {statas?.isLoading && (
            <LoadingAnimation color={colors.PrimaryBlack} />
          )}
          {editable ? (education?._id ? "Update" : "Save") : "Edit"}
        </p>
      )}
      {!editable ? (
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-sm text-primary">
            {education?.degree} {education?.fieldOfStudy}
          </span>
          <span className="font-medium text-xs text-light-black">
            {education?.school} |{" "}
            {moment(education?.startDate).format("MMM YYYY")}
            {education?.endDate &&
              `- ${moment(education?.endDate).format("MMM YYYY")}`}
          </span>
          <span className="font-normal text-xs text-light-black">
            {education?.location}
          </span>
        </div>
      ) : (
        <form className="grid grid-cols-2 gap-4">
          <CustomInput
            label={"Degree"}
            name="degree"
            placeholder={"Ex: Bachelor's"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Field of Study"}
            name="fieldOfStudy"
            placeholder={"Ex: Business"}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"School"}
            name="school"
            placeholder={"Ex: Boston University"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Location"}
            name="location"
            placeholder={"Ex: London, UK"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="date"
            label={"Start Date"}
            name="startDate"
            placeholder={"Ex: Project Manager"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="date"
            label={"End Date"}
            name="endDate"
            placeholder={"Ex: Project Manager"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
        </form>
      )}
    </div>
  );
};

const Education = ({ isEditing, candidateId }) => {
  const { user } = useSelector((state) => state.auth);
  const [educations, setEducations] = useState(user?.educations ?? []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [certificates, setCertificates] = useState(user?.certificates ?? []);

  const handleAddEducation = () => {
    setEducations((prev) => {
      return [
        {
          degree: "",
          fieldOfStudy: "",
          school: "",
          location: "",
          startDate: "",
          endDate: "",
          type: 1,
          isEditing: true,
        },
        ...prev,
      ];
    });
  };

  const handleAddCertificate = () => {
    setCertificates((prev) => {
      return [
        {
          degree: "",
          fieldOfStudy: "",
          school: "",
          location: "",
          startDate: "",
          endDate: "",
          type: 2,
          isEditing: true,
        },
        ...prev,
      ];
    });
  };

  const fetchEducation = async () => {
    try {
      setLoading(true);
      let response;
      if (candidateId) {
        response = await getCertificateOrEducation({
          candidateId: candidateId,
        });
      } else {
        response = await getCertificateOrEducation();

        const { success, data } = response;
        if (success) {
          dispatch(setUserData({ ...data }));
        }
      }
      const { success, data } = response;
      if (success) {
        setEducations(data?.educations);
        setCertificates(data?.certificates);
      } else {
        console.error("Failed to fetch skills from skill service.");
      }
    } catch (error) {
      console.error("Error fetching skills:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducation();
  }, []);

  if (loading) {
    return (
      <div className="flex flex-1  flex-col items-center justify-center">
        <LoadingAnimation color={colors.PrimaryBlack} />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className={`flex flex-col items-start gap-3 mb-8`}>
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Education
        </span>
        <div className="flex flex-col gap-6 w-[90%] mt-2">
          {educations?.map((education, index) => {
            return (
              <EducationInput
                key={index}
                customId={index}
                education={education}
                setEducations={setEducations}
                isEditing={isEditing}
              />
            );
          })}
        </div>
        {isEditing && (
          <button
            onClick={handleAddEducation}
            className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
          >
            + Add Education
          </button>
        )}
        <div className="flex flex-1 w-full border-b border-light-grey my-8" />
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Certifications
        </span>
        <div className="flex flex-col gap-6 w-[90%] mt-2">
          {certificates?.map((certificate, index) => {
            return (
              <EducationInput
                key={index}
                customId={index}
                education={certificate}
                setEducations={setCertificates}
                isEditing={isEditing}
              />
            );
          })}
        </div>
        {isEditing && (
          <button
            onClick={handleAddCertificate}
            className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
          >
            + Add Certificate
          </button>
        )}
      </div>
    </div>
  );
};

export default Education;
