import * as React from "react";
const MailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#6F6F6F"
        d="M12.25.583H1.75A1.75 1.75 0 0 0 0 2.333v11.084h14V2.333a1.75 1.75 0 0 0-1.75-1.75ZM1.75 1.75h10.5a.583.583 0 0 1 .583.583v.39L8.238 7.317a1.754 1.754 0 0 1-2.476 0L1.167 2.722v-.389a.583.583 0 0 1 .583-.583Zm-.583 10.5V4.375l3.77 3.768a2.92 2.92 0 0 0 4.126 0l3.77-3.768v7.875H1.167Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MailIcon;
