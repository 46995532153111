import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const getJobs = async (payload) => {
  try {
    const { data } = await axios.post(api.getJobList, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getJobById = async (jobId) => {
  try {
    const { data } = await axios.get(api.getJobDetails + `/${jobId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const shareJob = async (payload) => {
  try {
    const { data } = await axios.post(api.shareJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const verifyJob = async ({ email, otp }) => {
  try {
    const { data } = await axios.get(api.verifyJob + `/${email}/${otp}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const verifyJobStatus = async ({ email }) => {
  try {
    const { data } = await axios.get(api.verifyJobStatus + `/${email}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const addJob = async (payload) => {
  try {
    const { data } = await axios.post(api.addJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateJob = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteJob = async (jobId) => {
  try {
    const { data } = await axios.delete(api.deleteJob + jobId);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const publishUnpublish = async (jobId, status) => {
  try {
    const { data } = await axios.patch(
      api.publishUnpublish + "/" + jobId + "/" + status
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const applyJob = async (payload) => {
  try {
    const { data } = await axios.post(api.applyJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const applicantList = async (jobId) => {
  try {
    const { data } = await axios.get(api.applicantList + `/${jobId}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobMessageList = async (id) => {
  try {
    const { data } = await axios.get(api.jobMessageList + `/${id}`);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const jobMessage = async (payload) => {
  try {
    const { data } = await axios.post(api.jobMessage, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const candidateAppliedJobs = async () => {
  try {
    const { data } = await axios.get(api.getCandidateAppliedJobs);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
