import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingAnimation from "../../assets/svg/LoadingAnimation";
import ResetIcon from "../../assets/svg/ResetIcon";
import { useState } from "react";

const GenerateUserNamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, isSignup } = location.state || {};
  const [isFocused, setIsFocused] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      isLoading: false,
      username: "",
    },
  });

  const states = watch();

  const handleUsernameSelect = (username) => {
    setSelectedUsername(username);
    setValue("username", username);
    setIsFocused(true);
  };

  const handleNext = () => {
    navigate("/email-verification", {
      state: {
        email: email,
        isSignup: isSignup,
      },
    });
  };

  return (
    <div className="flex flex-col self-center justify-center h-full w-6/12">
      <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 relative bg-white self-center justify-self-center overflow-y-scroll no-scrollbar">
        <label className="font-semibold text-3xl mb-2">Select username</label>
        <label className="text-light-black font-medium px-10 text-center">
          Pick a username for your account. You can always change it later.
        </label>
        <form
          className="flex flex-col w-full pt-10 overflow-y-auto no-scrollbar h-max gap-1"
          onSubmit={handleSubmit(handleNext)}
        >
          <div className="flex flex-col items-start gap-2">
            <CustomInput
              placeholder={"@username"}
              name="username"
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
              renderRight={<ResetIcon />}
              className={`${
                isFocused || selectedUsername
                  ? "border-black"
                  : "border-light-grey"
              }`}
              onFocus={() => setIsFocused(true)}
            />
            <div className="flex gap-2">
              <p
                className="border border-light-grey rounded-[20px] bg-[#F7F7F7] py-1.5 px-4 text-xs cursor-pointer"
                onClick={() => handleUsernameSelect("pooja0001")}
              >
                pooja0001
              </p>
              <p
                className="border border-light-grey rounded-[20px] bg-[#F7F7F7] py-1.5 px-4 text-xs cursor-pointer"
                onClick={() => handleUsernameSelect("pooja99")}
              >
                pooja99
              </p>
              <p
                className="border border-light-grey rounded-[20px] bg-[#F7F7F7] py-1.5 px-4 text-xs cursor-pointer"
                onClick={() => handleUsernameSelect("pooja9009")}
              >
                pooja9009
              </p>
            </div>
          </div>

          <button
            type="submit"
            disabled={states?.isLoading}
            className="bg-secondary flex items-center justify-center rounded-full mt-10 py-3"
          >
            {states?.isLoading && <LoadingAnimation />}
            <span className="text-white font-medium">Next</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default GenerateUserNamePage;
