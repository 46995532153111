import { useEffect, useState } from "react";
import { jobMessage, jobMessageList } from "../../../../endpoints/job.service";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../utils/theme";
import { useSnackbar } from "notistack";
import moment from "moment";

const MessagesTab = ({ applicationDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isComposing, setIsComposing] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const pollingInterval = 3000;

  const fetchMessages = async (id) => {
    try {
      setLoadingMessages(true);
      const { success, data } = await jobMessageList(id);
      if (success) {
        setMessages(data);
      }
    } catch (error) {
      enqueueSnackbar(error + "Error fetching messages", {
        variant: "error",
      });
    } finally {
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    fetchMessages(applicationDetails?._id);

    const intervalId = setInterval(() => {
      fetchMessages(applicationDetails?._id);
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [applicationDetails]);

  const handleComposeClick = () => {
    setIsComposing(true);
  };

  const handleCloseClick = () => {
    setIsComposing(false);
    setMessage("");
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    try {
      setIsLoading(true);
      await jobMessage({ jobApplicationId: applicationDetails?._id, message });
      setMessage("");
      setIsComposing(false);
      enqueueSnackbar("Message sent successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error + "Error sending message", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-9">
        <button
          className={`text-xs text-primary font-medium py-2 px-9 border border-[#ECECEC] rounded ${
            isComposing ? "bg-[#F7F7F7]" : "bg-[#F1F5F9]"
          }`}
          onClick={handleComposeClick}
          disabled={isComposing}
        >
          Componse
        </button>
        <p className="text-sm font-semibold text-primary">
          To{" "}
          {applicationDetails?.job?.user?.firstName +
            " " +
            applicationDetails?.job?.user?.lastName ?? "-"}
        </p>
      </div>

      {isComposing && (
        <button
          className="text-primary text-xs font-medium flex justify-self-end mr-36"
          onClick={handleCloseClick}
        >
          Close
        </button>
      )}
      <div
        className={`bg-[#FAFAFA] py-7 px-16 rounded ${
          isComposing ? "mt-2" : "mt-6"
        } space-y-8 ${isComposing && "mx-28 pt-8 px-6"}`}
      >
        {!isComposing ? (
          messages.map((item) => (
            <div className="flex flex-col gap-2">
              <p className="text-[13px] font-semibold text-primary pl-3">
                {item.firstName} {item.lastName}
              </p>
              <p className="text-xs font-medium text-light-black pl-3">
                {moment(item?.createdAt).format("h:mm a DD MMMM YYYY")}
              </p>
              <p className="text-xs font-medium text-light-black p-3 bg-[#F1F5F9] rounded">
                {item.message}
              </p>
            </div>
          ))
        ) : (
          <div className="flex flex-col gap-4">
            <textarea
              className="p-4 bg-white rounded text-xs font-medium border border-[#ECECEC]"
              rows="18"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button
              className="text-xs text-primary font-medium py-2 px-4 border border-[#ECECEC] rounded bg-[#F1F5F9] self-end flex items-center disabled:cursor-not-allowed"
              onClick={handleSendMessage}
              disabled={isLoading || !message.trim()}
            >
              {isLoading && (
                <LoadingAnimation
                  color={colors.PrimaryBlack}
                  className={"w-4 h-4"}
                />
              )}
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesTab;
