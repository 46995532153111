import * as React from "react";
const PhoneOutline = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#6F6F6F"
        d="M12.997 2.42 11.147.57l-3.65 3.658 1.988 1.989C8.686 8.002 7.45 9.244 5.723 9.99L3.733 8 .07 11.649l1.85 1.849A3.438 3.438 0 0 0 4.374 14.5C8.605 14.5 14 9.104 14 4.875c0-.94-.355-1.808-1.003-2.456ZM4.375 13.332a2.268 2.268 0 0 1-1.627-.659L1.72 11.648 3.728 9.64l1.709 1.71.356-.135c2.31-.88 3.972-2.538 4.929-4.923l.146-.356-1.71-1.715 2.007-2.007 1.027 1.027c.426.426.66 1.003.66 1.627 0 3.634-4.825 8.459-8.46 8.459l-.017.005Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default PhoneOutline;
