import {
  REMOVE_TOKEN,
  LOGIN_USER,
  AUTH_USER_UPDATE,
  SET_AUTH_LOADING,
  LOGIN_TOKEN,
  SET_USER_DATA,
} from "../actions/action.types";
const getRefreshToken = () => {
  localStorage.getItem("refreshToken");
};
const initialState = {
  logged_in: false,
  loading: true,
  authenticated: false,
  user: null,
  companyDetails: {},
  token: "",
  refreshToken: getRefreshToken,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REMOVE_TOKEN:
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return { ...initialState, loading: false };

    case LOGIN_USER:
      let { user, companyDetails, token, refreshToken } = payload;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      return {
        ...state,
        logged_in: true,
        loading: false,
        authenticated: true,
        user: user,
        companyDetails: companyDetails ?? {},
        token: token,
      };

    case SET_USER_DATA:
      return {
        ...state,
        logged_in: true,
        loading: false,
        authenticated: true,
        user: { ...state.user, ...payload },
        companyDetails: {
          ...state.companyDetails,
          ...(payload?.companyDetails ? payload?.companyDetails : {}),
        },
        token: payload?.token,
      };

    case AUTH_USER_UPDATE:
      return {
        ...state,
        user: payload,
      };
    case SET_AUTH_LOADING:
      // if((payload == false) && (window.location.pathname != "/" && window.location.pathname != "/register" && window.location.pathname != "/login")){
      //     window.location.href = "/login";
      // }
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
