import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileImg from "../../assets/profilePic1.png";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import {
  getSkillIndividualPercentage,
  searchSkill,
} from "../../endpoints/skill.service";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { candidateStatus, skillsUsed } from "../../utils/constants";
import Skeleton from "../MatchDetailPage/components/Skeleton";
import { Input } from "../MatchDetailPage/MatchDetailPage";
import { twMerge } from "tailwind-merge";

export const SkillInput = ({
  type = "text",
  placeholder = "Skill",
  value = "",
  inputContainerStyle,
}) => {
  const [showSkillsUsed, setShowSkillsUsed] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [textValue, setTextValue] = useState(value);

  const handleTextChange = async (text) => {
    setTextValue(text);
    try {
      const { success, data } = await searchSkill(text);
      if (success && data?.length) {
        setShowSuggestions(true);
        setSuggestions(data);
      } else {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    } catch (error) {
      setShowSuggestions(false);
      setSuggestions([]);
    }
  };

  return (
    <div
      className={twMerge(
        "flex items-center w-full bg-white rounded-md border px-3 relative border-[#f5f5f5]",
        inputContainerStyle
      )}
    >
      <input
        onClick={() => type === "skillUsed" && setShowSkillsUsed(true)}
        className="bg-transparent w-full text-[11px] font-medium h-9 outline-none"
        placeholder={placeholder}
        value={textValue}
        onChange={(e) => handleTextChange(e?.target?.value)}
      />
      {/* {type === "skill" && showSuggestions ? (
        <div
          className="flex flex-col absolute top-10 left-0 bg-gray-50 rounded-md border z-50"
        >
          {suggestions?.map((item) => {
            return (
              <div
                onClick={() => {
                  setTextValue(item?.title);
                  setShowSuggestions(false);
                }}
                className="hover:bg-white px-4 py-1"
              >
                <span className="font-medium text-[11px] text-primary">
                  {item?.title}
                </span>
              </div>
            );
          })}
        </div>
      ) : null} */}
      {type === "skill" && showSuggestions ? (
        <div className="absolute top-full left-0 w-full bg-gray-50 rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto">
          {suggestions?.map((item) => {
            return (
              <div
                key={item?._id}
                onClick={() => {
                  setTextValue(item?.title);
                  setShowSuggestions(false);
                }}
                className="hover:bg-white px-4 py-2 cursor-pointer"
              >
                <span className="font-medium text-[11px] text-primary">
                  {item?.title}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}

      {type === "rating" ? (
        <span className="font-medium text-[11px] text-primary">/&nbsp;10</span>
      ) : null}
      {type === "skillUsed" && showSkillsUsed ? (
        <div
          className="flex flex-col absolute top-full left-0 bg-gray-50 rounded-md border z-50"
          onMouseLeave={() => setShowSkillsUsed(false)}
        >
          {Object.entries(skillsUsed)?.map(([key]) => {
            return (
              <div
                onClick={() => {
                  setTextValue(key);
                  setShowSkillsUsed(false);
                }}
                className="hover:bg-white px-4 py-1"
              >
                <span className="font-medium text-[11px] text-primary">
                  {key}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const DetailPage = () => {
  const navigate = useNavigate();
  const { addedSkills } = useSelector((state) => state.search);
  const { state } = useLocation();
  const { candidateDetails } = state;
  const [skillDetails, setSkillDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (candidateDetails?.candidateId) {
      setLoading(true);
      const payload = {
        candidateId: candidateDetails?.candidateId,
        searchedSkills: addedSkills?.map(({ id, ...rest }) => rest),
      };
      getSkillIndividualPercentage(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setSkillDetails(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [candidateDetails?.candidateId]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate("/search/possible-match")}
        className="flex items-center self-start mb-5"
      >
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      <div className="flex flex-1 flex-col max-h-fit overflow-y-hidden p-5 justify-center items-center bg-[#F9FAFB] rounded-md">
        {loading ? (
          <Skeleton />
        ) : (
          <div className="flex flex-col gap-2.5 overflow-auto mt-8 no-scrollbar w-full">
            {/* Candidate detail */}
            <Link
              to={"/search/search-user-profile"}
              state={candidateDetails?.candidateId}
              className="flex items-center w-full gap-5 cursor-pointer"
            >
              <div className="flex py-2 px-4 bg-white gap-4 flex-1 justify-between h-14 items-center">
                <div className="flex items-center gap-4">
                  <img
                    src={candidateDetails?.profilePic}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex flex-col justify-between gap-2">
                    <span className="font-medium text-primary text-xs">
                      {candidateDetails?.name}
                    </span>
                    <span className="font-medium text-primary text-xs">
                      {candidateDetails?.jobTitle}
                    </span>
                  </div>
                </div>
                <span className="font-medium text-green-700 text-xs pr-12">
                  {candidateStatus[candidateDetails?.status]}
                </span>
              </div>
              <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                {candidateDetails?.matchingPercentage?.toFixed(1)}% matched
              </span>
              <div className="flex flex-1 py-2 px-4 bg-white gap-4 justify-between h-14 items-center">
                <p className="text-xs font-medium text-light-black">
                  Ad-hoc search
                </p>
              </div>
            </Link>

            <div className="flex items-center w-full gap-32 mt-5">
              <label className="font-semibold text-sm text-light-black flex-1">
                Candidate Skills
              </label>
              <label className="font-semibold text-sm text-light-black flex-1">
                Matched Skills
              </label>
            </div>

            <div className="flex mt-3 gap-32">
              <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
                <span className="font-semibold text-xs text-primary">
                  Skill
                </span>
                <span className="font-semibold text-xs text-primary">
                  Rating
                </span>
                <span className="font-semibold text-xs text-primary">
                  Years
                </span>
                <span className="font-semibold text-xs text-primary">When</span>
                <div />
              </div>
              <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2">
                <span className="font-semibold text-xs text-primary">
                  Skill
                </span>
                <span className="font-semibold text-xs text-primary">
                  Rating
                </span>
                <span className="font-semibold text-xs text-primary">
                  Years
                </span>
                <span className="font-semibold text-xs text-primary">When</span>
                <div />
              </div>
            </div>
            {/* Skills List */}
            <div className="flex flex-col gap-2.5">
              {Array.isArray(skillDetails?.matchedSkillsPercentages) &&
                skillDetails?.matchedSkillsPercentages?.map((skill) => {
                  return (
                    <div className="flex items-center">
                      <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                        <SkillInput
                          readOnly={true}
                          type="skill"
                          value={skill?.candidateSkill?.title}
                        />
                        <SkillInput
                          readOnly={true}
                          type="rating"
                          placeholder="Rating"
                          value={skill?.candidateSkill?.rating}
                        />
                        <SkillInput
                          readOnly={true}
                          placeholder="Years"
                          value={skill?.candidateSkill?.years}
                        />
                        <SkillInput
                          readOnly={true}
                          type="skillUsed"
                          placeholder="When last used"
                          value={Object.keys(skillsUsed).find(
                            (key) =>
                              skillsUsed[key] ===
                              skill?.candidateSkill?.lastUsed
                          )}
                        />
                      </div>
                      <div className="w-72 flex items-center justify-center">
                        <span className="text-xs border-b pb-2 font-medium italic text-light-black">
                          {skill?.matchingPercentage?.toFixed(1)}% matched
                        </span>
                      </div>
                      <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                        <SkillInput
                          readOnly={true}
                          type="skill"
                          value={skill?.searchedskill?.title}
                        />
                        <SkillInput
                          readOnly={true}
                          type="rating"
                          placeholder="Rating"
                          value={skill?.searchedskill?.rating}
                        />
                        <SkillInput
                          readOnly={true}
                          placeholder="Years"
                          value={skill?.searchedskill?.years}
                        />
                        <SkillInput
                          readOnly={true}
                          type="skillUsed"
                          placeholder="When last used"
                          value={Object.keys(skillsUsed).find(
                            (key) =>
                              skillsUsed[key] === skill?.searchedskill?.lastUsed
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* Non matched skills */}
            {Array.isArray(skillDetails?.unMatchedSearchedSkills) &&
            skillDetails?.unMatchedSearchedSkills?.length ? (
              <div className="flex flex-col w-[43.5%] border-t-2 ml-auto mt-6 pt-4">
                <label className="font-semibold text-sm text-light-black flex-1">
                  Non-matched Skills
                </label>
                <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] w-full gap-2 mt-3">
                  <span className="font-semibold text-xs text-primary">
                    Skill
                  </span>
                  <span className="font-semibold text-xs text-primary">
                    Rating
                  </span>
                  <span className="font-semibold text-xs text-primary">
                    Years
                  </span>
                  <span className="font-semibold text-xs text-primary">
                    When
                  </span>
                  <div />
                </div>
                {skillDetails?.unMatchedSearchedSkills?.map((skill) => {
                  return (
                    <div className="grid grid-cols-[1.5fr_0.5fr_0.4fr_1fr] items-center w-full gap-2">
                      <SkillInput
                        readOnly={true}
                        type="skill"
                        value={skill?.title}
                      />
                      <SkillInput
                        readOnly={true}
                        type="rating"
                        placeholder="Rating"
                        value={skill?.rating}
                      />
                      <SkillInput
                        readOnly={true}
                        placeholder="Years"
                        value={skill?.years}
                      />
                      <SkillInput
                        readOnly={true}
                        type="skillUsed"
                        placeholder="When last used"
                        value={Object.keys(skillsUsed).find(
                          (key) => skillsUsed[key] === skill?.lastUsed
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailPage;
