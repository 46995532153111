import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import regexList from "../../../utils/regexList";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { getValueOrPlaceholder } from "../../../utils/helperFunction";

const ContactDetails = forwardRef(
  ({ isEditing, handleSave, profileDetails }, ref) => {
    const { user } = useSelector((state) => state.auth);

    const {
      handleSubmit,
      control,
      watch,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      defaultValues: {
        address: user?.address,
        email: user?.email,
      },
    });

    useEffect(() => {
      if (isEditing) {
        setValue("address", user?.address);
        setValue("phoneNo", (user?.countryCode + user?.phoneNo).toString());
        setValue("email", user?.email);
      }
    }, [isEditing, user, setValue]);

    useImperativeHandle(ref, () => handleSubmit(handleSave));

    return (
      <div>
        {!isEditing ? (
          <div className="h-full flex flex-1 overflow-y-scroll no-scrollbar w-4/5 justify-between grid-cols-3">
            <div className="flex flex-1 flex-col">
              <span className="font-montserrat text-[#383838] font-bold text-sm mb-4">
                Address
              </span>
              <span className="font-montserrat text-primary font-medium text-xs leading-5 mb-3">
                {getValueOrPlaceholder(
                  user?.address ?? profileDetails?.candidateDetails?.address
                )}
              </span>
            </div>
            <div className="flex flex-1 flex-col pl-10">
              <span className="font-montserrat text-[#383838] font-bold text-sm mb-4">
                Contact Number
              </span>
              <span className="font-montserrat text-primary font-medium text-xs leading-5 mb-3">
                {user?.countryCode}{" "}
                {user?.phoneNo ?? profileDetails?.candidateDetails?.phoneNo}
              </span>
            </div>
            <div className="flex  flex-col">
              <span className="font-montserrat text-[#383838] font-bold text-sm mb-4">
                Email Address
              </span>
              <span className="font-montserrat text-primary font-medium text-xs leading-5 mb-3">
                {profileDetails
                  ? profileDetails?.candidateDetails?.email
                  : user?.email}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-5 w-[90%]">
            <CustomInput
              type="text"
              label={"Address"}
              name={"address"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />

            <CustomInput
              type="phone"
              label={"Contact Number"}
              name={"phoneNo"}
              rules={{
                required: "This field is required.",
              }}
              control={control}
              errors={errors}
            />

            <CustomInput
              label={"Email Address"}
              placeholder={"abc@gmail.com"}
              inActiveOutlineColor="border border-px"
              bgColor={"bg-white"}
              control={control}
              name={"email"}
              rules={{
                required: "This field is required.",
                pattern: {
                  value: regexList.email,
                  message: "Please enter a valid email.",
                },
              }}
              errors={errors}
            />
          </div>
        )}
      </div>
    );
  }
);

export default ContactDetails;
