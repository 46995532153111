import { forwardRef, useCallback, useEffect, useState } from "react";
import { getExperienceList } from "../../../../endpoints/experience.service";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../../store/actions/auth.action";
import { ExperienceData, ExperienceProps } from "./experience.types";
import ExperienceBlock from "./experienceBlock/experienceBlock";
import PlusIcon from "../../../../assets/svg/PlusIcon";
import { isNullOrEmpty } from "../../../../utils/helperFunction";
import LoadingAnimation from "../../../../assets/svg/LoadingAnimation";
import { colors } from "../../../../utils/theme";

const Experience = forwardRef<HTMLDivElement, ExperienceProps>(
  (
    { isEditing, handleNext, handleEditClose, showProfile, candidateId },
    ref
  ) => {
    const { user } = useSelector((state: any) => state.auth);
    const [experiences, setExperiences] = useState<ExperienceData[]>(
      user?.experience ?? []
    );
    const [isFetching, setIsFetching] = useState<boolean>(
      user?.experience ? true : false
    );
    const [showModal, setShowModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setShowModal(isEditing);
    }, [isEditing]);

    const closeModal = (): void => {
      setShowModal(false);
      handleEditClose();
    };

    useEffect(() => {
      dispatch(setUserData({ experience: experiences?.filter((x) => x?._id) }));
    }, [experiences, dispatch]);

    const fetchExperienceList = useCallback(async () => {
      try {
        setIsFetching(true);
        const { success, data } = await getExperienceList({
          candidateId: candidateId,
        });
        if (success) {
          setExperiences(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }, [candidateId]);

    useEffect(() => {
      fetchExperienceList();
    }, [fetchExperienceList]);

    if (isFetching) {
      return (
        <div className="flex flex-1  flex-col items-center justify-center">
          <LoadingAnimation color={colors.PrimaryBlack} />
        </div>
      );
    }

    return (
      <div ref={ref} className="flex flex-col">
        {isNullOrEmpty(experiences) ? (
          <div className="flex flex-1  flex-col items-center justify-center">
            <p className="text-primary font-semibold">
              No Work Experience Created
            </p>
            {!showProfile && (
              <>
                <p className="mt-2 mb-5">Create a Work Experience Now!</p>
                <button
                  onClick={() => setShowModal(true)}
                  className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-medium text-sm"
                >
                  <PlusIcon className={"w-4 h-4"} fill={"white"} />
                  Add Experience
                </button>
              </>
            )}
          </div>
        ) : (
          <ExperienceBlock experiences={experiences} showOnly />
        )}

        {showModal && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
            onClick={closeModal}
          >
            <div
              className="flex overflow-hidden flex-col bg-white rounded-lg relative w-11/12 max-w-[50%] max-h-[75%] "
              onClick={(e) => e.stopPropagation()}
            >
              <ExperienceBlock
                popUpShow
                experiences={experiences}
                closeModal={closeModal}
                setExperiences={setExperiences}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

Experience.displayName = "Experience";

export default Experience;
