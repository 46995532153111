const ResetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#2E2E2E"
        d="M23.651 7.165a.798.798 0 0 0-1.109.21l-1.628 2.388a10.51 10.51 0 0 0-2.861-5.24 10.509 10.509 0 0 0-7.48-3.098c-2.826 0-5.482 1.1-7.48 3.098-4.124 4.124-4.124 10.835 0 14.96a10.545 10.545 0 0 0 7.48 3.092c2.709 0 5.417-1.03 7.48-3.093a.798.798 0 0 0-1.13-1.129c-3.501 3.502-9.199 3.502-12.7 0-3.502-3.502-3.502-9.2 0-12.701a8.923 8.923 0 0 1 6.35-2.63c2.399 0 4.654.934 6.35 2.63a8.918 8.918 0 0 1 2.485 4.74l-2.834-1.933a.798.798 0 0 0-.9 1.319l4.228 2.883v.001c.067.045.139.08.212.102l.007.003.037.008a.798.798 0 0 0 .854-.323l2.85-4.177a.798.798 0 0 0-.21-1.11Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ResetIcon;
