import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "../../../assets/svg/ChevronLeftIcon";
import { applicantList, getJobById } from "../../../endpoints/job.service";
import Spinner from "../../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { setJobDetail } from "../../../store/actions/job.action";

const tabs = [
  { name: "Job Post", path: "job-post" },
  { name: "Applicants", path: "applicants" },
  { name: "Shortlisted", path: "shortlisted" },
];

const JobDetail = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { jobId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const jobResponse = await getJobById(jobId);
        const { data: jobData, success: jobSuccess } = jobResponse;
        if (jobSuccess) {
          const applicantResponse = await applicantList(jobId);
          const { data: applicantData, success: applicantSuccess } =
            applicantResponse;
          if (applicantSuccess) {
            dispatch(setJobDetail({ ...jobData, applicantData }));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button
        onClick={() => navigate("/job")}
        className="flex items-center self-start"
      >
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>

      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-24 border-b px-3 mt-4 pb-2 pl-32">
            {tabs?.map((item, index) => {
              const isActive = pathname?.includes(item?.path);
              return (
                <button
                  onClick={() => navigate(item?.path)}
                  key={index}
                  className={`${
                    isActive
                      ? "border-secondary text-primary"
                      : "text-[#BABABA] border-transparent"
                  } text-sm font-semibold border-b-2 py-1 px-3`}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
          <div className="flex flex-1 flex-col overflow-y-hidden no-scrollbar">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default JobDetail;
