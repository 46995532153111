import { useState } from "react";
import { Controller } from "react-hook-form";

const DropdownInput = ({
  type = "dropdown",
  placeholder,
  value,
  onChange,
  dropdownList = [],
  errors,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionSelect = (option) => {
    onChange(option);
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center w-full bg-white rounded-md border px-3">
        <input
          className="bg-transparent w-full text-xs font-medium h-9 outline-none pb-0.5"
          placeholder={placeholder}
          value={value || ""}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          readOnly
        />
      </div>

      {isDropdownOpen && (
        <div
          className="absolute top-full left-0 w-full bg-white rounded-md border border-gray-300 z-50 shadow-lg max-h-52 overflow-y-auto"
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          {dropdownList.map((item, index) => (
            <div
              key={index}
              className="hover:bg-gray-100 px-4 py-2 cursor-pointer"
              onClick={() => handleOptionSelect(item)}
            >
              <span className="font-medium text-xs text-primary">{item}</span>
            </div>
          ))}
        </div>
      )}

      {errors?.message && (
        <span className="whitespace-pre text-xs mt-2 text-orange-700">
          {errors?.message}
        </span>
      )}
    </div>
  );
};

export const Dropdown = ({
  label,
  name,
  control,
  rules,
  errors,
  options,
  placeholder = "Select",
}) => (
  <div>
    <p className="text-primary text-xs font-medium mb-2">
      {label} <span className="text-red-500">*</span>
    </p>
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <DropdownInput
          value={value}
          onChange={onChange}
          dropdownList={options}
          placeholder={placeholder}
          errors={errors?.[name]}
        />
      )}
    />
  </div>
);
