import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

// Experience
export const getExperienceList = async (payload) => {
  try {
    const { data } = await axios.get(
      api.getExperienceList,
      payload ? { params: payload } : undefined
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const addExperience = async (payload) => {
  try {
    const { data } = await axios.post(api.addExperience, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateExperience = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateExperience, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteExperience = async (id) => {
  try {
    const { data } = await axios.delete(api.deleteExperience + "/" + id);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateSkill = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateSkill, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteSkill = async (id) => {
  try {
    const { data } = await axios.delete(api.deleteSkill + "/" + id);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const listSkill = async () => {
  try {
    const { data } = await axios.get(api.listSkill + "?type=1");
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getSkills = async () => {
  try {
    const { data } = await axios.get(api.getContactSkills);
    return data;
  } catch (err) {
    return handleError(err);
  }
};
