// export const userTypes = [
//   {
//     type: "admin",
//     value: 1,
//   },
//   {
//     type: "recruiter",
//     value: 2,
//   },
//   {
//     type: "candidate",
//     value: 3,
//   },
// ];

export const userTypes = {
  ADMIN: 1,
  RECRUITER: 2,
  CANDIDATE: 3,
  REFERRAL_AMBASSADOR: 4

}

export const RecruiterTypes = {
  ADMIN: 1,
  SUBADMIN: 2,
  MEMBER: 3
}

export const RecruiterApprovalStatus = {
  REQUESTED: 1,
  ACCEPTED: 2,
  REJECTED: 3
}


export const candidateStatus = {
  AVAILABLE: "Available",
  CONTRACTED: "Contracted",
  OPEN_TO_OPPORTUNITIES: "Open to opportunities",
  UNAVAILABLE: "Unavailable",
};

export type SkillsUsed = "Current" | "This Year" | "Last Year" | "2+ years";

export const skillsUsed: Record<SkillsUsed, number> = {
  Current: 1,
  "This Year": 2,
  "Last Year": 3,
  "2+ years": 4,
};

export const gender = [
  {
    label: "Male",
    value: 1,
  },
  {
    label: "Female",
    value: 2,
  },
  {
    label: "Others",
    value: 3,
  },
];

const levelSelect = [
  {
    label: "Beginner",
    value: 1,
  },
  {
    label: "Foundation",
    value: 2,
  },
  {
    label: "Intermediate",
    value: 3,
  },
  {
    label: "Expert",
    value: 4,
  },
  {
    label: "Elite",
    value: 5,
  },
];

const questionTypes = [
  {
    label: "Multiple Choice Question",
    value: "multiple-choice",
  },
  {
    label: "Check Box Question",
    value: "check-box",
  },
  {
    label: "Single Question/Answer",
    value: "single-answer",
  },
];

const sliderValues = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 9.7,
    label: "10",
  },
];

const skillsLastUsed = [
  {
    label: "Current",
    value: "current",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
  {
    label: "2+ years ago",
    value: "2yearsAgo",
  },
];

enum PublishStatus {
  PUBLISH = 1,
  UNPUBLISH
}

const JobStatus = {
  DRAFT: 1,
  LIVE: 2,
  CLOSED: 3,
  FULFILLED: 4,
  UNPUBLISHED: 5,
  EXPIRED: 6
};


// enum JobStatus {
//   DRAFT = 1,
//   LIVE,
//   CLOSED,
//   FULFILLED,
//   UNPUBLISHED,
//   EXPIRED
//   }

// const JobTypeEnum = {
//   FULLTIME: 1,
//   PARTTIME: 5,
//   REMOTE: 6,
//   CONTRACT: 2,
//   INTERNSHIP: 3,
//   CASUAL: 4,
//   HYBRID: 7,
// };

enum JobTypeEnum {
  FULLTIME = 1,
  CONTRACT,
  PARTTIME
}


enum WorkPlaceOptionsEnum {
  ONSITE = 1,
  HYBRID,
  REMOTE
}

const WorkPlaceOptions = [
  {
    label: 'On-site',
    value: WorkPlaceOptionsEnum?.ONSITE
  },
  {
    label: 'Hybrid',
    value: WorkPlaceOptionsEnum?.HYBRID
  },
  {
    label: 'Remote',
    value: WorkPlaceOptionsEnum?.REMOTE
  },
]

const JobType = [
  {
    label: "Full Time",
    value: JobTypeEnum?.FULLTIME,
  },
  {
    label: "Contract",
    value: JobTypeEnum?.CONTRACT,
  },
  // {
  //   label: "Internship",
  //   value: JobTypeEnum?.INTERNSHIP,
  // },
  // {
  //   label: "Casual",
  //   value: JobTypeEnum?.CASUAL,
  // },
  {
    label: "Part Time",
    value: JobTypeEnum?.PARTTIME,
  },
  // {
  //   label: "Remote",
  //   value: JobTypeEnum?.REMOTE,
  // },
  // {
  //   label: "Hybrid",
  //   value: JobTypeEnum?.HYBRID,
  // },
];

const JobSkills = [
  {
    label: "current",
    value: 1,
  },
  {
    label: "this year",
    value: 2,
  },
  {
    label: "last year",
    value: 3,
  },
  {
    label: "2+ years",
    value: 4,
  },
];

const datePostedType = [
  {
    label: "Last 24 Hours",
    value: 1,
  },
  {
    label: "Last 7 Days",
    value: 2,
  },
  {
    label: "Last 14 Days",
    value: 3,
  },
  {
    label: "Last 30 Days",
    value: 4,
  },
];

const CandidateAssessmentStatusEnum = {
  ASSESSMENT_RECEIVED: 1,
  ASSESSMENT_INPROGRESS: 2,
  ASSESSMENT_COMPLETED: 3,
};

const CandidateAssessmentStatus = [
  {
    label: "Assessment Received",
    value: CandidateAssessmentStatusEnum?.ASSESSMENT_RECEIVED,
  },
  {
    label: "Assessment InProgress",
    value: CandidateAssessmentStatusEnum?.ASSESSMENT_INPROGRESS,
  },
  {
    label: "Completed Assessment",
    value: CandidateAssessmentStatusEnum?.ASSESSMENT_COMPLETED,
  },
];

export const recruiterApprovalStatus = {
  ALL: 1,
  PENDING: 2,
  APPROVED: 3,
  // PENDING: 1,
  // APPROVED: 2,
  // REJECTED: 3,
};

export {
  levelSelect,
  questionTypes,
  sliderValues,
  skillsLastUsed,
  WorkPlaceOptions,
  JobStatus,
  JobType,
  JobTypeEnum,
  JobSkills,
  datePostedType,
  CandidateAssessmentStatus,
};
