import * as React from "react";

const AboutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={props.width || 18}
    height={props.height || 18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M20.925 3.61a.498.498 0 0 0-.288-.307c-.103-.041-2.549-1-7.391-1.245a15.317 15.317 0 0 0-.205-1.414.5.5 0 0 0-.291-.353C12.723.278 12.062 0 11 0S9.277.278 9.25.291a.496.496 0 0 0-.291.353c-.007.032-.107.51-.205 1.414-4.842.245-7.288 1.204-7.391 1.245a.498.498 0 0 0-.288.307C1.031 3.743 0 6.912 0 12.001s1.031 8.258 1.075 8.391c.046.14.151.252.288.307.132.054 3.312 1.303 9.637 1.303 6.325 0 9.505-1.249 9.637-1.303a.498.498 0 0 0 .288-.307C20.969 20.259 22 17.09 22 12.001s-1.031-8.258-1.075-8.391ZM9.886 1.135c.229-.06.614-.134 1.114-.134.5 0 .885.074 1.114.134.074.443.209 1.413.279 2.889a44.222 44.222 0 0 0-2.788 0c.07-1.476.205-2.445.279-2.889h.002Zm10.158 18.714c-.811.271-3.849 1.152-9.044 1.152s-8.233-.882-9.044-1.152C1.727 19.042 1 16.161 1 12.001s.727-7.041.956-7.848c.68-.227 2.941-.881 6.708-1.087-.032.432-.06.911-.079 1.46a.5.5 0 0 0 .521.517 46.886 46.886 0 0 1 3.787 0 .503.503 0 0 0 .521-.517 36.896 36.896 0 0 0-.079-1.46c3.766.205 6.028.86 6.708 1.087.229.807.956 3.688.956 7.848s-.727 7.041-.956 7.848h.001Zm-4.171-2.437a.501.501 0 0 1-.403.581.501.501 0 0 1-.581-.403c-.333-1.839-1.459-2.589-3.889-2.589-2.43 0-3.556.75-3.889 2.589a.508.508 0 0 1-.581.403.501.501 0 0 1-.403-.581c.427-2.359 1.93-3.411 4.873-3.411 2.943 0 4.446 1.052 4.873 3.411Zm-4.87-4.411c1.926-.012 2.963-1.046 2.998-3.009-.032-1.89-1.125-2.979-3.004-2.991-1.899.012-2.964 1.074-2.998 2.991v.018c.035 1.946 1.072 2.98 3.004 2.991Zm-.006-5c1.344.009 1.98.646 2.004 1.991-.025 1.399-.625 2-1.998 2.009-1.358-.008-1.977-.624-2.004-2 .027-1.375.646-1.991 1.998-2Z"
    />
  </svg>
);

export default AboutIcon;
