import * as React from "react";
const ExperienceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M19.567 5.309a.501.501 0 0 0-.354-.319c-.127-.031-2.449-.587-5.621-.853v-.926C13.592 1.44 12.149 0 10.355 0a3.218 3.218 0 0 0-3.218 3.211v.926c-3.167.266-5.493.822-5.62.852a.5.5 0 0 0-.354.32C1.115 5.445 0 8.675 0 12.997c0 4.359 1.116 7.557 1.164 7.69a.5.5 0 0 0 .353.319c.167.041 4.141.992 8.849.992s8.681-.952 8.848-.992a.499.499 0 0 0 .354-.32c.048-.136 1.163-3.366 1.163-7.689 0-4.359-1.116-7.556-1.163-7.69l-.001.002ZM8.137 3.212c0-1.219.995-2.211 2.238-2.211 1.223 0 2.218.992 2.218 2.211v.854a37.16 37.16 0 0 0-4.456 0v-.854ZM2.023 5.9c.976-.212 4.452-.903 8.342-.903 3.89 0 7.37.691 8.343.903.193.624.669 2.33.897 4.61-1.094.232-4.938.976-9.239.976-4.301 0-8.143-.743-9.238-.975.229-2.269.703-3.983.895-4.611Zm16.684 14.197c-.976.212-4.451.903-8.342.903-3.891 0-7.371-.691-8.343-.903C1.764 19.264 1 16.499 1 12.998c0-.51.017-1.006.046-1.483 1.232.256 4.782.916 8.819.967v1.439a.5.5 0 0 0 1 0v-1.439c4.031-.052 7.587-.712 8.82-.967.029.477.045.972.045 1.483 0 3.465-.766 6.258-1.023 7.099Z"
    />
  </svg>
);
export default ExperienceIcon;
